import { UniqueIdentifier } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LiHTMLAttributes } from "react";

interface SortableItemProps extends LiHTMLAttributes<HTMLLIElement> {
  isSortable?: boolean;
}

function SortableItem({
  isSortable = true,
  ...props
}: Readonly<SortableItemProps>) {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: props.id as UniqueIdentifier });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <li
      className="flex w-full items-center gap-2 rounded p-1 pl-2 text-slate-600 even:bg-slate-200/50 dark:text-slate-300 even:dark:bg-slate-800/50"
      id={props.id}
      ref={setNodeRef}
      style={style}
    >
      {props.children}
      {isSortable && (
        <FontAwesomeIcon
          {...attributes}
          {...listeners}
          className={`h-7 w-7 text-slate-400 transition-colors [touch-action:manipulation] hover:text-slate-500 focus:outline-none dark:text-slate-500 hover:dark:text-slate-400 ${
            isDragging ? "cursor-grabbing" : "cursor-grab"
          }`}
          icon={light("grip-lines")}
        />
      )}
    </li>
  );
}

export default SortableItem;

import "./wdyr";

import { KindeProvider } from "@kinde-oss/kinde-auth-react";
import * as Sentry from "@sentry/react";
import Plausible from "plausible-tracker";
import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { RouterProvider, createBrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import smoothscroll from "smoothscroll-polyfill";
import App from "./App";
import Loading from "./components/Loading/Loading";
import { Theme } from "./constants/enums/Theme";
import "./i18n";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import Admin from "./routes/admin/Admin";
import AdminBlitzModifiers from "./routes/admin/BlitzModifiers/BlitzModifiers";
import AdminBoxes from "./routes/admin/Boxes/Boxes";
import AdminEmoji from "./routes/admin/Emoji/Emoji";
import AdminEmojis from "./routes/admin/Emojis/Emojis";
import AdminEvent from "./routes/admin/Event/Event";
import AdminEventTypes from "./routes/admin/EventTypes/EventTypes";
import AdminEvents from "./routes/admin/Events/Events";
import AdminGroups from "./routes/admin/Groups/Groups";
import AdminIndex from "./routes/admin/Index/Index";
import AdminOther from "./routes/admin/Other/Other";
import AdminPowers from "./routes/admin/Powers/Powers";
import AdminRecommendations from "./routes/admin/Recommendations/Recommendations";
import AdminTags from "./routes/admin/Tags/Tags";
import AdminVersion from "./routes/admin/Version/Version";
import AdminVersions from "./routes/admin/Versions/Versions";
import AdminWorlds from "./routes/admin/Worlds/Worlds";
import { store } from "./store/Store";

const container = document.getElementById("app");
const root = createRoot(container!);
const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <App
        title="Unleash the Magic: Disney Emoji Blitz Character Guide"
        description="Find the perfect Disney Emoji Blitz characters to ace multiple missions using just one emoji. Check out detailed descriptions for each emoji, filter by event emojis, and keep track of your favorite characters and levels!"
      />
    ),
  },
  {
    path: "blitz-mode",
    element: (
      <App
        title="Which Disney Emoji Blitz character triggers Blitz Mode the most?"
        description="Discover the ultimate Disney Emoji Blitz character to unleash Blitz Mode repeatedly or boost your Blitz bar. Find your perfect match now!"
        urlSearchParams={new URLSearchParams({ powers: "blitzMode" })}
      />
    ),
  },
  {
    path: "create-bombbles",
    element: (
      <App
        title="Looking for the best Disney Emoji Blitz character to make Bombbles?"
        description="Get ready to create explosive magic with Disney Emoji Blitz characters! Find the best character to make Bombbles and experience the ultimate blast. "
        urlSearchParams={new URLSearchParams({ powers: "createBombbles" })}
      />
    ),
  },
  {
    path: "clear-emojis",
    element: (
      <App
        title="Need help choosing the best Disney Emoji Blitz character to clear emojis?"
        description="Clear emojis like a pro with the best Disney Emoji Blitz character! Explore our website to find the ultimate character that will help you conquer the game. Start clearing emojis now!"
        urlSearchParams={new URLSearchParams({ powers: "clearEmojis" })}
      />
    ),
  },
  {
    path: "give-coins",
    element: (
      <App
        title="Which Disney Emoji Blitz character is best for earning coins quickly?"
        description="Discover the ultimate Disney Emoji Blitz character to maximize your coin earnings in a single game. Unleash the magic and boost your gameplay now!"
        urlSearchParams={new URLSearchParams({ powers: "giveCoins" })}
      />
    ),
  },
  {
    path: "drop-items",
    element: (
      <App
        title="How can I find the top character for dropping items in Disney Emoji Blitz?"
        description="Uncover the ultimate Disney Emoji Blitz character to excel in item events and collect tons of items."
        urlSearchParams={new URLSearchParams({ powers: "dropItems" })}
      />
    ),
  },
  {
    path: "create-lightning-clouds",
    element: (
      <App
        title="Which Disney Emoji Blitz character is best for creating Lightning Clouds?"
        description="Discover the top Disney Emoji Blitz character for creating tons of Lightning Clouds. Boost your game with the perfect emoji now!"
        urlSearchParams={
          new URLSearchParams({ powers: "createLightningClouds" })
        }
      />
    ),
  },
  {
    path: "create-rainbow-stars",
    element: (
      <App
        title="Who is the most effective Disney Emoji Blitz character for Rainbow Stars?"
        description="Unleash the power of Rainbow Stars in Disney Emoji Blitz with the best character selection. Find your favorite and conquer the game like never before!"
        urlSearchParams={new URLSearchParams({ powers: "createRainbowStars" })}
      />
    ),
  },
  {
    path: "create-sunshines",
    element: (
      <App
        title="Which Disney Emoji Blitz character is best for generating Sunshines?"
        description="Discover the perfect Disney Emoji Blitz character to unlock endless Sunshines! Explore our website for the best character recommendations and tips."
        urlSearchParams={new URLSearchParams({ powers: "createSunshines" })}
      />
    ),
  },
  {
    path: "overcharge-emojis",
    element: (
      <App
        title="What are the Top OverCharge Emojis in Disney Emoji Blitz?"
        description="Discover the ultimate collection of OverCharge Emojis in Disney Emoji Blitz. Unleash your power with these electrifying characters!"
        urlSearchParams={new URLSearchParams({ powers: "overchargeEmojis" })}
      />
    ),
  },
  {
    path: "shuffle-emojis",
    element: (
      <App
        title="Find Your Favorite Shuffle Emojis in Disney Emoji Blitz"
        description="Uncover the ultimate Shuffle Emojis in Disney Emoji Blitz! Elevate your gameplay with our expert recommendations."
        urlSearchParams={new URLSearchParams({ powers: "shuffleEmojis" })}
      />
    ),
  },
  {
    path: "multiply-score",
    element: (
      <App
        title="Score Big with Top Disney Emoji Blitz Characters"
        description="Unleash the power of the best Disney Emoji Blitz characters to skyrocket your score and secure the top spot on the leaderboard!"
        urlSearchParams={new URLSearchParams({ powers: "multiplyScore" })}
      />
    ),
  },
  {
    path: "add-time",
    element: (
      <App
        title="Which Disney Emoji Blitz characters add time to your game?"
        description="Discover the top Disney Emoji Blitz characters that will boost your game with extra time! Find the best ones here and level up your gameplay today."
        urlSearchParams={new URLSearchParams({ powers: "addTime" })}
      />
    ),
  },
  {
    path: "transform-emojis",
    element: (
      <App
        title="Discover the Top Disney Emoji Blitz Characters for Emoji Transformations"
        description="Find the best Transform emoji, then filter further to complete multiple missions with a single emoji."
        urlSearchParams={new URLSearchParams({ powers: "transformEmojis" })}
      />
    ),
  },
  {
    path: "clear-bottom-half",
    element: (
      <App
        title="What are the best Disney Emoji Blitz characters for clearing the bottom half?"
        description="Unlock the best Disney Emoji Blitz characters for crushing the bottom half of the board. Maximize your scores with our character recommendations!"
        urlSearchParams={
          new URLSearchParams({ recommendations: "clearBottomHalf" })
        }
      />
    ),
  },
  {
    path: "clear-bottom-row",
    element: (
      <App
        title="Which Disney Emoji Blitz characters clear the bottom row?"
        description="Uncover the ultimate Disney Emoji Blitz characters for clearing the bottom row and dominating the game!"
        urlSearchParams={
          new URLSearchParams({ recommendations: "clearBottomRow" })
        }
      />
    ),
  },
  {
    path: "clear-top-half",
    element: (
      <App
        title="Which Disney Emoji Blitz characters are best for clearing the top half of the board?"
        description="Uncover the top Disney Emoji Blitz characters that will help you clear the board's upper half. Level up your gameplay and achieve victory!"
        urlSearchParams={
          new URLSearchParams({ recommendations: "clearTopHalf" })
        }
      />
    ),
  },
  {
    path: "clear-top-row",
    element: (
      <App
        title="Looking for tips on clearing the top row in Disney Emoji Blitz?"
        description="Discover the top Disney Emoji Blitz characters to clear rows & win! Get the best strategies & tips here."
        urlSearchParams={
          new URLSearchParams({ recommendations: "clearTopRow" })
        }
      />
    ),
  },
  {
    path: "combo-lightning-clouds",
    element: (
      <App
        title="Master Lightning Cloud Combos with the Best Disney Emoji Blitz Characters"
        description="Discover the top Disney Emoji Blitz characters for creating powerful Lightning Cloud combos! Unleash your emoji skills today."
        urlSearchParams={
          new URLSearchParams({
            recommendations: "comboLightningCloudPlusLightningCloud",
          })
        }
      />
    ),
  },
  {
    path: "combo-lightning-clouds-and-rainbow-stars",
    element: (
      <App
        title="Which Disney Emoji Blitz characters are best for combining Lightning Clouds and Rainbow Stars?"
        description="Unleash the ultimate Disney Emoji Blitz characters who excel at pairing Lightning Clouds and Rainbow Stars for epic gameplay success!"
        urlSearchParams={
          new URLSearchParams({
            recommendations: "comboLightningCloudPlusRainbowStar",
          })
        }
      />
    ),
  },
  {
    path: "combo-lightning-clouds-and-sunshines",
    element: (
      <App
        title="Top Disney Emoji Blitz Characters for Lightning Cloud and Sunshine Combos"
        description="Discover the top Disney Emoji Blitz characters for creating Lightning Clouds and Sunshines combos! Unlock new strategies and boost your gameplay today."
        urlSearchParams={
          new URLSearchParams({
            recommendations: "comboLightningCloudPlusSunshine",
          })
        }
      />
    ),
  },
  {
    path: "combo-rainbow-stars-and-items",
    element: (
      <App
        title="Master the Art of Combining Rainbow Stars and Items in Disney Emoji Blitz"
        description="Discover the ultimate Disney Emoji Blitz characters to create powerful combos with Rainbow Stars and Items. Unleash your emoji magic today!"
        urlSearchParams={
          new URLSearchParams({
            recommendations: "comboRainbowStarPlusItem",
          })
        }
      />
    ),
  },
  {
    path: "combo-rainbow-stars",
    element: (
      <App
        title="Discover the Top Disney Emoji Blitz Characters for Combining Rainbow Stars"
        description="Uncover the ultimate Disney Emoji Blitz characters to create magic by combining two Rainbow Stars! Unleash your emoji power now."
        urlSearchParams={
          new URLSearchParams({
            recommendations: "comboRainbowStarPlusRainbowStar",
          })
        }
      />
    ),
  },
  {
    path: "combo-sunshines-and-rainbow-stars",
    element: (
      <App
        title="Top Disney Emoji Blitz Characters for Sunshine and Rainbow Star Combos"
        description="Explore our site to find the best Disney Emoji Blitz characters perfect for combining Sunshines and Rainbow Stars. Start winning today!"
        urlSearchParams={
          new URLSearchParams({
            recommendations: "comboSunshinePlusRainbowStar",
          })
        }
      />
    ),
  },
  {
    path: "combo-sunshines",
    element: (
      <App
        title="Master the art of combining Sunshines: Disney Emoji Blitz character guide."
        description="Unlock the secrets to pairing two Sunshines in Disney Emoji Blitz with our top character recommendations. Maximize your gameplay now!"
        urlSearchParams={
          new URLSearchParams({
            recommendations: "comboSunshinePlusSunshine",
          })
        }
      />
    ),
  },
  {
    path: "earn-coins",
    element: (
      <App
        title="How can I maximize my coin earnings in Disney Emoji Blitz?"
        description="Discover the top Disney Emoji Blitz characters that will help you earn heaps of coins! Unleash their powers and dominate the game."
        urlSearchParams={
          new URLSearchParams({
            recommendations: "earnCoins",
          })
        }
      />
    ),
  },
  {
    path: "high-score",
    element: (
      <App
        title="Which Disney Emoji Blitz characters are best for high scores?"
        description="Elevate your Disney Emoji Blitz game with the ultimate characters for achieving high scores! Master the game with these top emojis."
        urlSearchParams={
          new URLSearchParams({
            recommendations: "highScore",
          })
        }
      />
    ),
  },
  {
    path: "use-power-multiple-times",
    element: (
      <App
        title="Who are the best Disney Emoji Blitz characters for multiple power activations?"
        description="Discover the ultimate Disney Emoji Blitz characters to unleash their powers multiple times in every game. Find your favorites now!"
        urlSearchParams={
          new URLSearchParams({
            recommendations: "usePowerMultipleTimes",
            sort: "trigger",
          })
        }
      />
    ),
  },
  {
    path: "admin",
    element: <Admin />,
    children: [
      {
        path: "",
        element: <AdminIndex />,
      },
      {
        path: "boxes",
        element: <AdminBoxes />,
      },
      {
        path: "emojis",
        element: <AdminEmojis />,
      },
      {
        path: "emojis/add",
        element: <AdminEmoji />,
      },
      {
        path: "emojis/edit/:id",
        element: <AdminEmoji />,
      },
      {
        path: "events",
        element: <AdminEvents />,
      },
      {
        path: "events/add",
        element: <AdminEvent />,
      },
      {
        path: "events/edit/:id",
        element: <AdminEvent />,
      },
      {
        path: "event-types",
        element: <AdminEventTypes />,
      },
      {
        path: "groups",
        element: <AdminGroups />,
      },
      {
        path: "powers",
        element: <AdminPowers />,
      },
      {
        path: "blitz-modifiers",
        element: <AdminBlitzModifiers />,
      },
      {
        path: "other",
        element: <AdminOther />,
      },
      {
        path: "recommendations",
        element: <AdminRecommendations />,
      },
      {
        path: "tags",
        element: <AdminTags />,
      },
      {
        path: "versions",
        element: <AdminVersions />,
      },
      {
        path: "versions/add",
        element: <AdminVersion />,
      },
      {
        path: "versions/edit/:id",
        element: <AdminVersion />,
      },
      {
        path: "worlds",
        element: <AdminWorlds />,
      },
    ],
  },
]);
const mql = window.matchMedia("(prefers-color-scheme: dark)");
const setTheme = () => {
  if (
    localStorage.getItem("theme") === Theme.Dark ||
    ((!localStorage.getItem("theme") ||
      localStorage.getItem("theme") === Theme.Auto) &&
      mql.matches)
  ) {
    document.documentElement.classList.add("dark");
    document
      .querySelector('meta[name="theme-color"]')
      ?.setAttribute("content", "#9333ea");
  } else {
    document.documentElement.classList.remove("dark");
    document
      .querySelector('meta[name="theme-color"]')
      ?.setAttribute("content", "#a855f7");
  }
};

setTheme();

mql.onchange = () => {
  setTheme();
};

const { trackPageview } = Plausible();

trackPageview();

Sentry.init({
  dsn: "https://dff9d909e3af44938986f777fcb75b4c@o1085638.ingest.sentry.io/6096673",
  // This enables automatic instrumentation (highly recommended)
  // If you only want to use custom instrumentation:
  // * Remove the `BrowserTracing` integration
  // * add `Sentry.addTracingExtensions()` above your Sentry.init() call
  integrations: [
    Sentry.browserTracingIntegration(),
    // Or, if you are using react router, use the appropriate integration
    // See docs for support for different versions of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  // For finer control of sent transactions you can adjust this value, or
  // use tracesSampler
  tracesSampleRate: 1.0,
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/debwizard\.com\/api/],
});

smoothscroll.polyfill();

root.render(
  <React.StrictMode>
    <Suspense fallback={<Loading />}>
      <Provider store={store}>
        <KindeProvider
          clientId="6e888be2620442b9a896ffca3e389288"
          domain="https://account.debwizard.com"
          isDangerouslyUseLocalStorage={process.env.NODE_ENV === "development"}
          logoutUri={window.location.origin}
          redirectUri={window.location.origin}
        >
          <HelmetProvider>
            <RouterProvider router={router} />
          </HelmetProvider>
        </KindeProvider>
      </Provider>
    </Suspense>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { IKImage } from "imagekitio-react";
import { useEffect, useState } from "react";
import { Control, Controller, FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select, {
  ClearIndicatorProps,
  DropdownIndicatorProps,
  MenuProps,
  MultiValueGenericProps,
  MultiValueRemoveProps,
  OptionProps,
  Props,
  StylesConfig,
  components,
} from "react-select";
import { WindowedMenuList } from "react-windowed-select";
import useSWR, { KeyedMutator } from "swr";
import tw from "twin.macro";
import { Theme } from "../../constants/enums/Theme";
import EmojiList from "../../constants/interfaces/EmojiList";

interface EmojiSelectProps
  extends Props<{ filePath: string; label: string; value: string }, true> {
  control: Control<FieldValues, any>;
  label?: JSX.Element | string;
  name: string;
  onChange?: any;
  villains?: boolean;
}

const ClearIndicator = (
  props: ClearIndicatorProps<
    { filePath: string; label: string; value: string },
    true
  >,
) => (
  <components.ClearIndicator {...props}>
    <FontAwesomeIcon className="h-4 w-4" icon={light("xmark")} />
  </components.ClearIndicator>
);
const DropdownIndicator = (
  props: DropdownIndicatorProps<
    { filePath: string; label: string; value: string },
    true
  >,
) => (
  <components.DropdownIndicator {...props}>
    <FontAwesomeIcon className="h-4 w-4" icon={light("chevron-down")} />
  </components.DropdownIndicator>
);
const Menu = (
  props: MenuProps<{ filePath: string; label: string; value: string }, true>,
) => <components.Menu {...props} className="menu"></components.Menu>;
const MultiValueLabel = (
  props: MultiValueGenericProps<{
    filePath: string;
    label: string;
    value: string;
  }>,
) => (
  <components.MultiValueLabel {...props}>
    <div className="flex items-center gap-1">
      <IKImage
        alt={props.data.label}
        height="16"
        lqip={{ active: true }}
        path={props.data.filePath}
        transformation={[
          {
            di: "error.png",
            dpr: window.devicePixelRatio.toString(),
            width: "16",
            height: "16",
            cropMode: "pad_resize",
          },
        ]}
        urlEndpoint={process.env.REACT_APP_IMAGEKIT_URL_ENDPOINT}
        width="16"
      />
      <span>{props.data.label}</span>
    </div>
  </components.MultiValueLabel>
);
const MultiValueRemove = (
  props: MultiValueRemoveProps<{
    filePath: string;
    label: string;
    value: string;
  }>,
) => {
  const innerProps = {
    ...props.innerProps,
    "aria-label": `Remove ${props.data.label}`,
  };

  return (
    <components.MultiValueRemove {...props} innerProps={innerProps}>
      <FontAwesomeIcon className="h-3" icon={light("xmark")} />
    </components.MultiValueRemove>
  );
};
const Option = (
  props: OptionProps<{ filePath: string; label: string; value: string }>,
) => {
  delete props.innerProps.onMouseMove;
  delete props.innerProps.onMouseOver;
  return <components.Option {...props}>{props.children}</components.Option>;
};
const styles: StylesConfig<
  { filePath: string; label: string; value: string },
  true
> = {
  clearIndicator: (provided) => ({
    ...provided,
    ...tw`p-[7px] text-slate-900 transition-colors hover:text-purple-500 dark:text-slate-100 dark:hover:text-purple-400`,
  }),
  control: (provided, state) => ({
    ...provided,
    ...(state.isFocused
      ? tw`ring-2 ring-purple-500/50 dark:ring-purple-400/50`
      : ``),
    ...tw`min-h-[30px] rounded border-slate-300 bg-white shadow-none hover:border-slate-300 dark:border-slate-600 dark:bg-slate-800 dark:hover:border-slate-600`,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    ...tw`p-[7px] text-slate-900 transition-colors hover:text-purple-500 dark:text-slate-100 dark:hover:text-purple-400`,
  }),
  groupHeading: (provided) => ({
    ...provided,
    height: 18,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    ...tw`bg-slate-400`,
  }),
  input: (provided) => ({
    ...provided,
    ...tw`text-slate-900 dark:text-slate-100 text-sm my-0`,
  }),
  menu: (provided) => ({
    ...provided,
    ...tw`mt-1 translate-y-1 rounded border border-slate-200 bg-white shadow-xl transition duration-200 dark:border-slate-600 dark:bg-slate-700`,
  }),
  multiValue: (provided) => ({
    ...provided,
    ...tw`m-0 h-6 gap-1 rounded-full bg-purple-500/20 text-purple-500 ring-2 ring-purple-500 dark:bg-purple-400/20 dark:text-purple-400 dark:ring-purple-400`,
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    ...tw`py-0.5 pl-2 pr-0 text-purple-500 dark:text-purple-400`,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    ...tw`rounded-none bg-transparent pl-0 pr-1.5 transition-colors hover:text-red-500 dark:hover:text-red-400`,
  }),
  option: (provided, state) => ({
    ...provided,
    ...(state.isFocused ? tw`bg-purple-500/20 dark:bg-purple-400/20` : ``),
    ...(state.isSelected
      ? tw`light:text-slate-100 !bg-purple-500 dark:!bg-purple-400`
      : ``),
    ...tw`text-slate-900 dark:text-slate-100 hover:bg-purple-500/20 dark:hover:bg-purple-400/20`,
    height: 40,
  }),
  placeholder: (provided) => ({
    ...provided,
    ...tw`text-slate-400`,
  }),
  singleValue: (provided) => ({
    ...provided,
    ...tw`text-sm text-slate-900 dark:text-slate-100`,
  }),
  valueContainer: (provided) => ({
    ...provided,
    ...tw`gap-2 px-[12px] py-[6px]`,
  }),
};

const EmojiSelect = ({
  control,
  label,
  name,
  onChange,
  villains,
  ...props
}: EmojiSelectProps) => {
  const { t } = useTranslation();
  const [currentTheme, setCurrentTheme] = useState<Theme>();
  const fetcher = (url: string) => axios.get(url).then((res) => res.data);
  const { data }: { data: Array<EmojiList>; mutate: KeyedMutator<any> } =
    useSWR(`${process.env.REACT_APP_API_ENDPOINT}/emojis/list`, fetcher);
  const emojis = (
    villains ? data?.filter((emoji) => emoji.box === "villain") : data
  )?.map((emoji) => ({
    filePath: emoji.filePath,
    label: t(`emojis.${emoji._id}`),
    value: emoji._id,
  }));
  const mql = window.matchMedia("(prefers-color-scheme: dark)");

  useEffect(() => {
    setCurrentTheme(() => {
      if (
        localStorage.getItem("theme") === Theme.Dark ||
        ((!localStorage.getItem("theme") ||
          localStorage.getItem("theme") === Theme.Auto) &&
          mql.matches)
      ) {
        return Theme.Dark;
      } else {
        return Theme.Light;
      }
    });

    mql.onchange = () => {
      setCurrentTheme(localStorage.getItem("theme") as Theme);
    };
  }, [mql]);

  return (
    <div className={`${props.className} flex flex-col`}>
      {label && (
        <label
          className="text-slate-600 dark:text-slate-300"
          htmlFor={props.inputId}
        >
          {typeof label === "string" ? t(label) : label}{" "}
          {!props.isDisabled && (
            <>
              {props.required ? (
                <span className="text-red-500 dark:text-red-400">*</span>
              ) : (
                <span className="text-sm text-slate-400">
                  ({t("optional")})
                </span>
              )}
            </>
          )}
        </label>
      )}
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Select
            {...field}
            {...props}
            blurInputOnSelect={!props.isMulti}
            closeMenuOnSelect={!props.isMulti}
            components={{
              ClearIndicator,
              DropdownIndicator,
              Menu,
              MenuList: WindowedMenuList,
              MultiValueLabel,
              MultiValueRemove,
              Option,
            }}
            formatOptionLabel={(data: {
              filePath: string;
              label: string;
              value: string;
            }) => (
              <div className="flex items-center gap-1">
                <IKImage
                  alt={data.label}
                  height="20"
                  lqip={{ active: true }}
                  path={data.filePath}
                  transformation={[
                    {
                      di:
                        currentTheme === Theme.Dark
                          ? "dark-error.png"
                          : "error.png",
                      dpr: window.devicePixelRatio.toString(),
                      width: "20",
                      height: "20",
                      cropMode: "pad_resize",
                    },
                  ]}
                  urlEndpoint={process.env.REACT_APP_IMAGEKIT_URL_ENDPOINT}
                  width="20"
                />
                {data.label}
              </div>
            )}
            menuPlacement="auto"
            onChange={(event) => {
              field.onChange(event);

              if (onChange) {
                onChange(event);
              }
            }}
            options={emojis}
            styles={styles}
            value={
              field.value?.length
                ? field.value.map((item: { label: string; value: string }) =>
                    emojis?.find((emoji) => emoji.value === item.value),
                  )
                : emojis?.find((emoji) => emoji.value === field.value?.value)
            }
          />
        )}
        rules={{ required: props.required }}
      />
    </div>
  );
};

export default EmojiSelect;

import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import axios from "axios";
import { IKImage } from "imagekitio-react";
import { camelCase } from "lodash";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useSWR, { KeyedMutator } from "swr";
import Button from "../../../components/Button/Button";
import DangerButton from "../../../components/DangerButton/DangerButton";
import Input from "../../../components/Input/Input";
import Document from "../../../constants/interfaces/Document";

function AdminRecommendations() {
  const { getToken } = useKindeAuth();
  const { t } = useTranslation();
  const {
    formState: { isValid },
    handleSubmit,
    register,
    reset,
  } = useForm<Document>();

  const fetcher = (url: string) => axios.get(url).then((res) => res.data);

  const {
    data,
    isLoading,
    mutate,
  }: { data: Document[]; isLoading: boolean; mutate: KeyedMutator<any> } =
    useSWR(`${process.env.REACT_APP_API_ENDPOINT}/recommendations`, fetcher);

  const formatRecommendation = (recommendation: string) => {
    if (recommendation.startsWith("combo")) {
      const images = recommendation
        .slice(5)
        .split("Plus")
        .map((image) => image.charAt(0).toLocaleLowerCase() + image.slice(1));

      return (
        <span className="inline-flex items-center">
          {t("recommendations.combo")}&nbsp;
          <IKImage
            alt={t(`recommendations.${images[0]}`)}
            className="h-6"
            height="24"
            lqip={{ active: true }}
            path={`${images[0]}.png`}
            transformation={[
              {
                dpr: window.devicePixelRatio.toString(),
                width: "24",
              },
            ]}
            urlEndpoint={process.env.REACT_APP_IMAGEKIT_URL_ENDPOINT}
            width="24"
          />
          &nbsp;+&nbsp;
          {images[1] === "item" ? (
            t("recommendations.item")
          ) : (
            <IKImage
              alt={t(`recommendations.${images[1]}`)}
              className="h-6"
              height="24"
              lqip={{ active: true }}
              path={`${images[1]}.png`}
              transformation={[
                {
                  dpr: window.devicePixelRatio.toString(),
                  width: "24",
                },
              ]}
              urlEndpoint={process.env.REACT_APP_IMAGEKIT_URL_ENDPOINT}
              width="24"
            />
          )}
        </span>
      );
    } else {
      return t(`recommendations.${recommendation}`);
    }
  };

  const onSubmit = async (formData: Document) => {
    const accessToken = await getToken();
    const newDocument: Document = {
      _id: camelCase(formData._id),
    };

    await axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/recommendations`,
        newDocument,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      )
      .then(() => {
        reset();
        mutate(
          [...data, newDocument].sort((a, b) => a._id.localeCompare(b._id)),
        );
      });
  };

  const deleteRecommendation = async (id: string) => {
    const accessToken = await getToken();

    if (
      window.confirm(
        t("admin.areYouSure", { value: t("recommendations.recommendation") }),
      )
    ) {
      await axios
        .delete(`${process.env.REACT_APP_API_ENDPOINT}/recommendations/${id}`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(() => {
          mutate();
        });
    }
  };

  return (
    <>
      <div className="mb-4 flex gap-2">
        <FontAwesomeIcon
          className="h-7 w-7 text-purple-500 transition-opacity dark:text-purple-400"
          icon={light("lightbulb-exclamation")}
        />

        <h1 className="text-xl font-bold text-slate-900 dark:text-slate-100">
          {t("recommendations.title")}
        </h1>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex gap-2 pr-1">
          <Input className="grow" name="_id" register={register} required />
          <Button disabled={!isValid} label={t("add")} type="submit">
            <FontAwesomeIcon
              className="h-5 w-5"
              icon={light("add")}
            ></FontAwesomeIcon>
          </Button>
        </div>
      </form>

      {!isLoading && !data?.length ? (
        <div className="relative mt-4 rounded border border-yellow-600 bg-yellow-500 p-2 text-center text-sm text-white dark:border-yellow-500 dark:bg-yellow-400 dark:text-yellow-900">
          <FontAwesomeIcon
            className="h-6 w-6"
            icon={light("square-dashed-circle-plus")}
          />
          <p>{t("admin.empty", { value: t("recommendations.title") })}</p>
        </div>
      ) : (
        <ol className="mt-4">
          {data
            ? data?.map((recommendation) => (
                <li
                  className="flex w-full items-center gap-2 rounded p-1 pl-2 text-slate-600 even:bg-slate-200/50 dark:text-slate-300 even:dark:bg-slate-800/50"
                  key={recommendation._id}
                >
                  <span className="grow">
                    {formatRecommendation(recommendation._id)}
                  </span>

                  <DangerButton
                    onClick={(e) => {
                      e.preventDefault();
                      deleteRecommendation(recommendation._id);
                    }}
                  ></DangerButton>
                </li>
              ))
            : null}
        </ol>
      )}
    </>
  );
}

export default AdminRecommendations;

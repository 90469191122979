import {
  duotone,
  light,
  solid,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import axios from "axios";
import "intersection-observer";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Link } from "react-router-dom";
import { Sort } from "../../constants/enums/Sort";
import Emoji from "../../constants/interfaces/Emoji";
import { useAppSelector } from "../../store/Hooks";
import AvailableFrom from "../AvailableFrom/AvailableFrom";
import Checkbox from "../Checkbox/Checkbox";
import EmojiBlitzModifiers from "../EmojiBlitzModifiers/EmojiBlitzModifiers";
import EmojiDetails from "../EmojiDetails/EmojiDetails";
import EmojiFlair from "../EmojiFlairPopover/EmojiFlairPopover";
import EmojiGroups from "../EmojiGroups/EmojiGroups";
import EmojiName from "../EmojiName/EmojiName";
import EmojiPowers from "../EmojiPowers/EmojiPowers";
import EmojiRecommendations from "../EmojiRecommendations/EmojiRecommendations";
import EmojiTags from "../EmojiTags/EmojiTags";
import EmojiTriggers from "../EmojiTriggers/EmojiTriggers";
import EmojiWorlds from "../EmojiWorlds/EmojiWorlds";
import Level from "../Level/Level";

type CardProps = {
  emoji: Emoji;
};

const Card = ({ emoji }: CardProps) => {
  const [favourite, setFavourite] = useState(emoji.favourite);
  const [isVisible, setIsVisible] = useState(false);
  const [level, setLevel] = useState(emoji.level);
  const [show, setShow] = useState(false);
  const { getPermission, getToken, isAuthenticated, login, user } =
    useKindeAuth();
  const { register, setValue, watch } = useFormContext();
  const boxBackgroundTint = {
    silver: "bg-gray-300/5 dark:bg-gray-500/5",
    gold: "bg-amber-300/5 dark:bg-amber-500/5",
    series1: "bg-amber-300/5 dark:bg-amber-500/5",
    series2: "bg-amber-300/5 dark:bg-amber-500/5",
    series3: "bg-amber-300/5 dark:bg-amber-500/5",
    story: "bg-pink-300/5 dark:bg-pink-500/5",
    rainbow: "bg-lime-300/5 dark:bg-lime-500/5",
    villain: "bg-violet-300/5 dark:bg-violet-500/5",
    crystal: "bg-cyan-300/5 dark:bg-cyan-400/5",
    platinum: "bg-blue-300/5 dark:bg-blue-500/5",
    cozyCrafts: "bg-orange-300/5 dark:bg-orange-500/5",
    stainedGlass: "bg-navy-300/5 dark:bg-navy-500/5"
  };
  const boxBorderColor = {
    silver: "border-gray-900/20 dark:border-gray-300/20",
    gold: "border-amber-900/20 dark:border-amber-300/20",
    series1: "border-amber-900/20 dark:border-amber-300/20",
    series2: "border-amber-900/20 dark:border-amber-300/20",
    series3: "border-amber-900/20 dark:border-amber-300/20",
    story: "border-pink-900/20 dark:border-pink-300/20",
    rainbow: "border-lime-900/20 dark:border-lime-300/20",
    villain: "border-violet-900/20 dark:border-violet-300/20",
    crystal: "border-cyan-800/20 dark:border-cyan-200/20",
    platinum: "border-blue-900/20 dark:border-blue-300/20",
    cozyCrafts: "border-orange-900/20 dark:border-orange-300/20",
    stainedGlass: "border-yellow-900/20 dark:border-yellow-100/20"
  };
  const boxClassNames = {
    silver: "bg-gradient-silver-to-r",
    gold: "bg-gradient-gold-to-r",
    series1: "bg-gradient-series-to-r",
    series2: "bg-gradient-series-to-r",
    series3: "bg-gradient-series-to-r",
    story: "bg-gradient-story-to-r",
    rainbow: "bg-gradient-rainbow-to-r",
    villain: "bg-gradient-villain-to-r",
    crystal: "bg-gradient-crystal-to-r",
    platinum: "bg-gradient-platinum-to-r",
    cozyCrafts: "bg-gradient-cozy-crafts-45deg",
    stainedGlass: "bg-gradient-stained-glass-details-border-to-r"
  };
  const emojiAnniversary =
    emoji.availableFrom &&
    new Date(emoji.availableFrom)?.getDate() === new Date().getDate() &&
    new Date(emoji.availableFrom).getMonth() === new Date().getMonth()
      ? new Date().getFullYear() - new Date(emoji.availableFrom).getFullYear()
      : 0;
  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => setIsVisible(entry.isIntersecting));
    },
    [],
  );
  const isLoading = useAppSelector((state) => state.loading.isLoading);
  const ref = useRef<HTMLDivElement>(null);
  const showAnimatePulse = isLoading && isVisible ? "animate-pulse" : "";
  const showBlitzModifiers = useAppSelector(
    (state) => state.settings.showBlitzModifiers,
  );
  const showGroups = useAppSelector((state) => state.settings.showGroups);
  const showPowers = useAppSelector((state) => state.settings.showPowers);
  const showRecommendations = useAppSelector(
    (state) => state.settings.showRecommendations,
  );
  const showTags = useAppSelector((state) => state.settings.showTags);
  const showTriggers = useAppSelector((state) => state.settings.showTriggers);
  const showWorlds = useAppSelector((state) => state.settings.showWorlds);
  const sort = useAppSelector((state) => state.settings.sort);
  const triggersHeight = () => {
    if (
      (emoji.triggers?.toFirstTrigger && !emoji.triggers?.toOvercharge) ||
      (emoji.triggers?.toOvercharge && !emoji.triggers?.toFirstTrigger)
    ) {
      return "h-[74px]";
    }

    if (emoji.triggers?.toFirstTrigger && emoji.triggers?.toOvercharge) {
      return "h-[98px]";
    }

    return `h-[50px]`;
  };

  const handleFavourite = async () => {
    if (isAuthenticated) {
      const accessToken = await getToken();

      axios
        .put(
          `${process.env.REACT_APP_API_ENDPOINT}/favourites/${user?.id}`,
          {
            emoji: emoji._id,
            favourite: !favourite,
          },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          },
        )
        .then(() => {
          setFavourite(!favourite);
        });
    }
  };

  const handleLevel = async (level: number) => {
    if (isAuthenticated) {
      const accessToken = await getToken();

      axios
        .put(
          `${process.env.REACT_APP_API_ENDPOINT}/levels/${user?.id}`,
          {
            emoji: emoji._id,
            level,
          },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          },
        )
        .then(() => {
          setLevel(level);
        });
    }
  };

  useEffect(() => {
    const current = ref.current;
    const observer = new IntersectionObserver(handleIntersection);

    setFavourite(emoji.favourite);
    setLevel(emoji.level);

    if (current) {
      observer.observe(current);

      return () => observer.unobserve(current);
    }
  }, [emoji.favourite, emoji.level, handleIntersection]);

  return (
    <>
      <Checkbox
        className="hidden"
        id={`selected[${emoji._id}]`}
        name="selected"
        register={register}
        value={emoji._id}
      />
      <div
        className={`${
          !isLoading && isVisible
            ? boxBorderColor[emoji.box as keyof typeof boxBorderColor]
            : "border-slate-300 dark:border-slate-700"
        } ${emoji.box === "cozyCrafts" ? "after:pointer-events-none after:absolute after:h-full after:w-full after:bg-[url('./assets/images/black-felt.png')] after:mix-blend-difference" : null} relative flex flex-col rounded border bg-white shadow dark:bg-slate-800`}
        id={emoji._id}
        ref={ref}
      >
        {/* Box */}
        {!isLoading && isVisible ? (
          <div
            className={`${
              boxClassNames[emoji.box as keyof typeof boxClassNames]
            } ${emoji.box === "cozyCrafts" ? "after:absolute after:-bottom-0.5 after:block after:w-full after:border-b-2 after:border-dashed after:border-indigo-500 after:content-['']" : null} relative h-1 rounded-t`}
          >
            {(emoji.box === "crystal" || emoji.box === "platinum") && (
              <FontAwesomeIcon
                className="absolute -right-1 -top-1 animate-[pulse_1s_ease-in-out_infinite] text-white drop-shadow-md"
                icon={duotone("sparkles")}
              ></FontAwesomeIcon>
            )}
          </div>
        ) : (
          <div
            className={`${showAnimatePulse} h-1 rounded-t bg-slate-100 dark:bg-slate-700`}
          ></div>
        )}

        <div
          className={`${
            !isLoading && isVisible
              ? boxBackgroundTint[emoji.box as keyof typeof boxBackgroundTint]
              : ""
          } flex grow flex-col p-2`}
        >
          {/* Name */}
          {!isLoading && isVisible ? (
            <div className="flex justify-between gap-2">
              <div className="flex items-start gap-2">
                <EmojiName
                  box={emoji.box}
                  filePath={emoji.filePath}
                  id={emoji._id}
                  showEffects
                />
              </div>

              <div className="flex items-start gap-3">
                {/* Edit */}
                {isAuthenticated && getPermission("admin").isGranted && (
                  <Link to={`/admin/emojis/edit/${emoji._id}`}>
                    <FontAwesomeIcon
                      className="mt-1 h-6 w-6 text-purple-500 dark:text-purple-400"
                      icon={light("pen-to-square")}
                    />
                  </Link>
                )}

                {/* Level */}
                <Level
                  arrayTo={emoji.maxLevel + 1}
                  className="mt-1"
                  disabled={
                    !(
                      emoji.availableFrom &&
                      new Date(emoji.availableFrom) < new Date()
                    )
                  }
                  level={level}
                  onClick={handleLevel}
                ></Level>

                {/* Favourite */}
                <button
                  name="Favourite"
                  onClick={isAuthenticated ? handleFavourite : (login as any)}
                  type="button"
                >
                  <FontAwesomeIcon
                    className="mt-1 h-6 w-6 shrink-0 text-red-500 dark:text-red-400"
                    icon={favourite ? solid("heart") : light("heart")}
                  />
                </button>

                {/* Details Button */}
                <button
                  className="mt-1 h-6 w-6 shrink-0 rounded-full border border-transparent bg-purple-500 text-xs text-white transition-colors hover:bg-purple-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500/50 dark:bg-purple-600 dark:text-slate-100 dark:hover:bg-purple-700 dark:focus-visible:ring-purple-600/50"
                  name="Details"
                  onClick={() => setShow(true)}
                  type="button"
                >
                  <FontAwesomeIcon icon={solid("info")}></FontAwesomeIcon>
                </button>

                {/* Selected Checkbox */}
                <Checkbox
                  checked={watch("selected").some(
                    (selected: { emoji: string; maxLevel: number }) =>
                      selected.emoji === emoji._id,
                  )}
                  className="mt-1 p-1"
                  disabled={
                    !!(
                      emoji.availableFrom &&
                      new Date(emoji.availableFrom) > new Date()
                    )
                  }
                  name={`${emoji._id}`}
                  onChange={(event) => {
                    const set: Set<{ emoji: string; maxLevel: number }> =
                      new Set(watch("selected"));

                    if (event.target.checked) {
                      set.add({
                        emoji: emoji._id,
                        maxLevel: emoji.maxLevel,
                      });
                    } else {
                      set.forEach((entry) => {
                        if (entry.emoji === emoji._id) {
                          set.delete(entry);
                        }
                      });
                    }

                    setValue("selected", Array.from(set));
                  }}
                />
              </div>
            </div>
          ) : (
            <div className={`${showAnimatePulse} flex h-8 gap-2`}>
              <div className="h-8 w-8 shrink-0 rounded bg-slate-100 dark:bg-slate-700"></div>
              <div className="mt-1 h-6 w-32 rounded bg-slate-100 dark:bg-slate-700"></div>
            </div>
          )}

          {(emoji.exclusive ||
            emoji.groupCollectionReward ||
            (emoji.banned && emoji.banned?.length > 0) ||
            emoji.interactivePower ||
            emojiAnniversary > 0 ||
            (emoji.stardustUpgradesAvailableFrom &&
              new Date(emoji.stardustUpgradesAvailableFrom) < new Date()) ||
            (emoji.availableFrom &&
              new Date(emoji.availableFrom) > new Date()) ||
            sort === Sort.Date) &&
            (!isLoading && isVisible ? (
              <div className="ml-10 flex gap-2">
                {/* Flair */}
                <EmojiFlair emoji={emoji}></EmojiFlair>

                {/* Available From */}
                {emoji.availableFrom && (
                  <AvailableFrom
                    date={emoji.availableFrom}
                    showPastDates={sort === Sort.Date}
                  />
                )}
              </div>
            ) : (
              <div className={`${showAnimatePulse} ml-10 flex gap-2`}>
                <div className="h-[24px] w-32 rounded-full bg-slate-100 dark:bg-slate-700"></div>
              </div>
            ))}

          <div className="mt-2 flex flex-col gap-2">
            {/* Tags */}
            {showTags &&
              emoji.tags.length > 0 &&
              (!isLoading && isVisible ? (
                <EmojiTags tags={emoji.tags} />
              ) : (
                <div className={`${showAnimatePulse} flex h-6 gap-2`}>
                  <div className="mx-1 h-6 w-6 shrink-0 rounded-full bg-slate-100 dark:bg-slate-700"></div>
                  <div className="h-[22px] w-64 rounded-full bg-slate-100 dark:bg-slate-700"></div>
                </div>
              ))}

            {/* Worlds */}
            {showWorlds &&
              emoji.worlds &&
              emoji.worlds.length > 0 &&
              (!isLoading && isVisible ? (
                <EmojiWorlds worlds={emoji.worlds} />
              ) : (
                <div className={`${showAnimatePulse} flex h-6 gap-2`}>
                  <div className="mx-1 h-6 w-6 shrink-0 rounded-full bg-slate-100 dark:bg-slate-700"></div>
                  <div className="h-[22px] w-64 rounded-full bg-slate-100 dark:bg-slate-700"></div>
                </div>
              ))}

            {/* Powers */}
            {showPowers &&
              Object.keys(emoji.powers).length > 0 &&
              (!isLoading && isVisible ? (
                <EmojiPowers maxLevel={emoji.maxLevel} powers={emoji.powers} />
              ) : (
                <div className={`${showAnimatePulse} flex h-6 gap-2`}>
                  <div className="mx-1 h-6 w-6 shrink-0 rounded-full bg-slate-100 dark:bg-slate-700"></div>
                  <div className="h-[22px] w-24 rounded-full bg-slate-100 dark:bg-slate-700"></div>
                </div>
              ))}

            {/* Blitz Modifiers */}
            {showBlitzModifiers &&
              emoji.blitzModifiers &&
              emoji.blitzModifiers.length > 0 &&
              (!isLoading && isVisible ? (
                <EmojiBlitzModifiers blitzModifiers={emoji.blitzModifiers} />
              ) : (
                <div className={`${showAnimatePulse} flex h-6 gap-2`}>
                  <div className="mx-1 h-6 w-6 shrink-0 rounded-full bg-slate-100 dark:bg-slate-700"></div>
                  <div className="h-4 w-32 rounded bg-slate-100 dark:bg-slate-700"></div>
                </div>
              ))}

            {/* Recommendations */}
            {showRecommendations &&
              emoji.recommendations &&
              Object.keys(emoji.recommendations).length > 0 &&
              (!isLoading && isVisible ? (
                <EmojiRecommendations
                  maxLevel={emoji.maxLevel}
                  recommendations={emoji.recommendations}
                />
              ) : (
                <div className={`${showAnimatePulse} flex h-6 gap-2`}>
                  <div className="mx-1 h-6 w-6 shrink-0 rounded-full bg-slate-100 dark:bg-slate-700"></div>
                  <div className="h-[22px] w-56 rounded-full bg-slate-100 dark:bg-slate-700"></div>
                </div>
              ))}

            {/* Groups */}
            {showGroups &&
              emoji.groups &&
              emoji.groups.length > 0 &&
              (!isLoading && isVisible ? (
                <EmojiGroups groups={emoji.groups} />
              ) : (
                <div className={`${showAnimatePulse} flex h-6 gap-2`}>
                  <div className="mx-1 h-6 w-6 shrink-0 rounded-full bg-slate-100 dark:bg-slate-700"></div>
                  <div className="h-[22px] w-28 rounded-full bg-slate-100 dark:bg-slate-700"></div>
                </div>
              ))}

            {/* Triggers */}
            {showTriggers &&
              emoji.triggers &&
              (!isLoading && isVisible ? (
                <EmojiTriggers
                  level={level}
                  toTriggerAverage={emoji.toTriggerAverage}
                  triggers={emoji.triggers}
                />
              ) : (
                <div
                  className={`${triggersHeight()} ${showAnimatePulse} flex gap-2`}
                >
                  <div className="mx-1 h-6 w-6 shrink-0 rounded-full bg-slate-100 dark:bg-slate-700"></div>
                  <div
                    className={`${triggersHeight} w-48 rounded bg-slate-100 dark:bg-slate-700`}
                  ></div>
                </div>
              ))}
          </div>
        </div>
      </div>

      {/* Emoji Details modal */}
      {show && (
        <EmojiDetails
          emoji={emoji}
          favourite={favourite}
          setFavourite={setFavourite}
          level={level}
          setLevel={setLevel}
          show={show}
          setShow={setShow}
        />
      )}
    </>
  );
};

export default memo(Card);

import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import axios from "axios";
import { ChangeEvent, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import useSWR, { KeyedMutator } from "swr";
import Button from "../../../components/Button/Button";
import Checkbox from "../../../components/Checkbox/Checkbox";
import DangerButton from "../../../components/DangerButton/DangerButton";
import EmojiName from "../../../components/EmojiName/EmojiName";
import Input from "../../../components/Input/Input";
import Loading from "../../../components/Loading/Loading";
import SortableItem from "../../../components/SortableItem/SortableItem";
import Emoji from "../../../constants/interfaces/Emoji";

const AdminEmojis = () => {
  const [items, setItems] = useState<UniqueIdentifier[]>([]);
  const [sortByDate, setSortByDate] = useState<boolean>(true);
  const { getToken } = useKindeAuth();
  const { t } = useTranslation();
  const hash = useRef("");
  const location = useLocation();
  const navigate = useNavigate();

  const fetcher = (url: string) =>
    axios.get(url).then((res) => {
      setItems(res.data.map((emoji: Emoji) => emoji._id));

      if (location.hash) {
        hash.current = location.hash.slice(1);

        setTimeout(() => {
          document
            .getElementById(hash.current)
            ?.scrollIntoView({ behavior: "smooth", block: "start" });
          hash.current = "";
        });
      }

      return res.data;
    });

  const {
    data,
    isLoading,
    mutate,
  }: { data: Emoji[]; isLoading: boolean; mutate: KeyedMutator<any> } = useSWR(
    `${process.env.REACT_APP_API_ENDPOINT}/emojis/list${sortByDate ? "?sort=date" : ""}`,
    fetcher,
  );

  const patchEmojis = async (items: UniqueIdentifier[]) => {
    const accessToken = await getToken();

    await axios
      .patch(
        `${process.env.REACT_APP_API_ENDPOINT}/emojis`,
        {
          items,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      )
      .then(() => {
        mutate(data.toSorted((a, b) => (a.position ?? 0) - (b.position ?? 0)));
      });
  };

  const deleteEmoji = async (id: string, fileId?: string) => {
    const accessToken = await getToken();

    if (window.confirm(t("admin.areYouSure", { value: t("emoji") }))) {
      await axios
        .delete(`${process.env.REACT_APP_API_ENDPOINT}/emojis/${id}`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(() => {
          if (fileId) {
            axios.delete(
              `${process.env.REACT_APP_API_ENDPOINT}/imagekit/${fileId}`,
              {
                headers: { Authorization: `Bearer ${accessToken}` },
              },
            );
          }

          mutate();
        });
    }
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setItems((items) => {
        const oldIndex = items?.indexOf(active.id);
        const newIndex = items?.indexOf(over?.id!);

        const patchedData = arrayMove(items, oldIndex, newIndex);

        patchEmojis(patchedData);

        return patchedData;
      });
    }
  };

  return (
    <>
      <div className="mb-4 flex gap-2">
        <FontAwesomeIcon
          className="h-7 w-7 text-purple-500 dark:text-purple-400"
          icon={light("smile")}
        />

        <h1 className="text-xl font-bold text-slate-900 dark:text-slate-100">
          {t("emojis.title")}
        </h1>
      </div>

      <Input
        className="mb-1"
        label="filter"
        name="filter"
        id="filter"
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const value = event.currentTarget?.value;
          const filterItems = setTimeout(() => {
            if (value?.length >= 2) {
              setItems(
                [...data]
                  .filter((emoji) =>
                    t(`emojis.${emoji._id}`)
                      .toLocaleLowerCase()
                      .includes(value.toLocaleLowerCase()),
                  )
                  .map((emoji) => emoji._id),
              );
            } else {
              setItems(data.map((emoji) => emoji._id));
            }
          }, 300);

          return () => clearTimeout(filterItems);
        }}
      />

      <div className="mb-2 flex items-center gap-2">
        <span className="text-slate-600 dark:text-slate-300">
          {t("sort.label")}
        </span>
        <Checkbox
          id="sortByDate"
          label="sort.date"
          name="sortByDate"
          onChange={(event) => setSortByDate(event.target.checked)}
          checked={sortByDate}
        />
      </div>

      <Button
        className="flex w-full gap-2"
        label={`${t("add")} ${t("emoji")}`}
        onClick={() => navigate("/admin/emojis/add")}
      >
        <FontAwesomeIcon
          className="h-5 w-5"
          icon={light("add")}
        ></FontAwesomeIcon>
        {t("add")} {t("emoji")}
      </Button>

      {isLoading && <Loading />}
      {!isLoading && !data?.length ? (
        <div className="relative mt-4 rounded border border-yellow-600 bg-yellow-500 p-2 text-center text-sm text-white dark:border-yellow-500 dark:bg-yellow-400 dark:text-yellow-900">
          <FontAwesomeIcon
            className="h-6 w-6"
            icon={light("square-dashed-circle-plus")}
          />
          <p>{t("admin.empty", { value: t("emojis.title") })}</p>
        </div>
      ) : (
        <ol className="mt-4 space-y-2">
          <DndContext
            collisionDetection={closestCenter}
            modifiers={[restrictToVerticalAxis]}
            onDragEnd={handleDragEnd}
            sensors={sensors}
          >
            <SortableContext
              items={items}
              strategy={verticalListSortingStrategy}
            >
              {items?.length
                ? items?.map((item) => {
                    const emoji = data?.find((emoji) => emoji._id === item);

                    return (
                      <SortableItem
                        key={emoji?._id}
                        id={emoji?._id}
                        isSortable={!sortByDate}
                      >
                        <div className="flex grow flex-row gap-2">
                          {emoji && (
                            <EmojiName
                              box={emoji.box}
                              filePath={emoji.filePath}
                              id={emoji._id}
                            ></EmojiName>
                          )}
                        </div>

                        <Button
                          label={`${t("edit")} ${t("emoji")}`}
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(`/admin/emojis/edit/${emoji?._id}`);
                          }}
                        >
                          <FontAwesomeIcon
                            className="h-5 w-5"
                            icon={light("pencil")}
                          ></FontAwesomeIcon>
                        </Button>

                        <DangerButton
                          onClick={(e) => {
                            e.preventDefault();
                            deleteEmoji(emoji?._id!, emoji?.fileId);
                          }}
                        ></DangerButton>
                      </SortableItem>
                    );
                  })
                : null}
            </SortableContext>
          </DndContext>
        </ol>
      )}
    </>
  );
};

export default AdminEmojis;

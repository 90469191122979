import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonHTMLAttributes } from "react";
import { useTranslation } from "react-i18next";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

const DangerButton = ({ ...props }: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <button
      {...props}
      aria-label={t("delete")}
      className="inline-flex min-w-[38px] items-center justify-center gap-1 rounded border border-transparent bg-red-500 p-2 text-sm font-bold text-white transition-colors hover:bg-red-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500/50 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-red-500 dark:bg-red-600 dark:text-slate-100 dark:hover:bg-red-700 dark:focus-visible:ring-red-600/50 disabled:dark:hover:bg-red-600"
    >
      <FontAwesomeIcon
        className="h-5 w-5"
        icon={light("trash")}
      ></FontAwesomeIcon>
    </button>
  );
};

export default DangerButton;

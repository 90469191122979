import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IKImage } from 'imagekitio-react';
import { useTranslation } from 'react-i18next';
import { Kind } from '../../constants/enums/Kind';
import { Filter } from '../../constants/types/Filter';
import { filterToggled } from '../../store/FiltersSlice';
import { useAppDispatch } from '../../store/Hooks';
import { loadingChanged } from '../../store/LoadingSlice';

type ChipProps = {
  checked: boolean;
  disabled?: boolean;
  filter: Filter;
};

const Chip = ({ checked, disabled = false, filter }: ChipProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const recommendationLabel = () => {
    if (
      filter.kind === Kind.Recommendations &&
      filter.value.startsWith('combo')
    ) {
      const images = filter.value
        .slice(5)
        .split('Plus')
        .map((image) => image.charAt(0).toLocaleLowerCase() + image.slice(1));

      return (
        <span className="flex items-center">
          {t('recommendations.combo')}&nbsp;
          <IKImage
            alt={t(`recommendations.${images[0]}`)}
            className="h-4"
            height="16"
            lqip={{ active: true }}
            path={`${images[0]}.png`}
            transformation={[
              {
                dpr: window.devicePixelRatio.toString(),
                width: '16',
              },
            ]}
            urlEndpoint={process.env.REACT_APP_IMAGEKIT_URL_ENDPOINT}
            width="16"
          />
          &nbsp;+&nbsp;
          {images[1] === 'item' ? (
            t('recommendations.item')
          ) : (
            <IKImage
              alt={t(`recommendations.${images[1]}`)}
              className="h-4"
              height="16"
              lqip={{ active: true }}
              path={`${images[1]}.png`}
              transformation={[
                {
                  dpr: window.devicePixelRatio.toString(),
                  width: '16',
                },
              ]}
              urlEndpoint={process.env.REACT_APP_IMAGEKIT_URL_ENDPOINT}
              width="16"
            />
          )}
        </span>
      );
    } else {
      return t(`${filter.kind}.${filter.value}`);
    }
  };

  return (
    <li className="first:pl-2 last:pr-2 shrink-0">
      <label className="relative top-px flex whitespace-nowrap">
        <input
          defaultChecked={checked}
          disabled={disabled}
          className="peer pointer-events-none hidden"
          onChange={() => {
            checked = !checked;
            setTimeout(() => {
              dispatch(loadingChanged(true));
              dispatch(filterToggled(filter));
            }, 150);
          }}
          type="checkbox"
        ></input>
        <FontAwesomeIcon
          className="pointer-events-none absolute top-1/2 left-1.5 h-3 w-3 -translate-y-1/2 text-purple-500 opacity-0 transition-opacity peer-checked:opacity-100 peer-checked:delay-75 dark:text-purple-400"
          icon={light('check')}
        />
        <FontAwesomeIcon
          className="pointer-events-none absolute top-1/2 left-1.5 hidden h-3 w-3 -translate-y-1/2 text-yellow-500 first-line:top-1/2 peer-disabled:block dark:text-yellow-400"
          icon={solid('triangle-exclamation')}
        />
        <span className="relative z-10 flex h-[22px] cursor-pointer items-start rounded-full border border-current px-2 py-0.5 text-xs text-slate-500 transition-all peer-checked:bg-purple-500/20 peer-checked:pl-5 peer-checked:text-purple-500 peer-checked:ring-1 peer-checked:ring-purple-500 peer-disabled:cursor-not-allowed peer-disabled:pl-5 peer-disabled:opacity-50 dark:text-slate-300 dark:peer-checked:bg-purple-400/20 dark:peer-checked:text-purple-400 dark:peer-checked:ring-purple-400">
          <span className="relative top-1/2 -translate-y-1/2 leading-none">
            {recommendationLabel()}
          </span>
        </span>
      </label>
    </li>
  );
};

export default Chip;

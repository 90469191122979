import { createSlice } from '@reduxjs/toolkit';
import Modal from '../constants/interfaces/Modal';

const initialState: Modal = {
  isOpen: false,
};

export const recommendationsSlice = createSlice({
  name: 'recommendations',
  initialState,
  reducers: {
    recommendationsOpenChanged: (state, action) => {
      state.isOpen = action.payload;
    },
  },
});

export const { recommendationsOpenChanged } = recommendationsSlice.actions;

export default recommendationsSlice.reducer;

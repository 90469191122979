export enum Kind {
  BlitzModifiers = "blitzModifiers",
  Boxes = "boxes",
  Emojis = "emojis",
  Groups = "groups",
  Levels = "levels",
  Other = "other",
  Powers = "powers",
  Recommendations = "recommendations",
  Tags = "tags",
  Worlds = "worlds",
}

import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Kind } from "../../constants/enums/Kind";
import { useAppSelector } from "../../store/Hooks";
import Chip from "../Chip/Chip";

type EmojiWorldsProps = {
  worlds: string[];
};

const EmojiWorlds = ({ worlds }: EmojiWorldsProps) => {
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const [scrollable, setScrollable] = useState(false);
  const { t } = useTranslation();
  const filteredWorlds = useAppSelector((state) => state.filters)
    .filter((filter) => filter.kind === Kind.Worlds)
    .map((filteredWorld) => filteredWorld.value);
  const layout = useAppSelector((state) => state.settings.layout);
  const scrollRef = useRef<HTMLUListElement>(null);

  const scrollLeft = useCallback(() => {
    const children = scrollRef.current?.children;

    if (children) {
      for (const child of Array.from(children).reverse()) {
        const offsetLeftMinusPadding = (child as HTMLElement).offsetLeft - 8;

        if (scrollRef.current?.scrollLeft > offsetLeftMinusPadding) {
          scrollRef.current?.scrollTo({
            left: offsetLeftMinusPadding,
            behavior: "smooth",
          });
          break;
        }
      }
    }
  }, [scrollRef]);
  const scrollRight = useCallback(() => {
    const children = scrollRef.current?.children;

    if (children) {
      for (const child of Array.from(children)) {
        const offsetLeftMinusPadding = (child as HTMLElement).offsetLeft - 8;

        if (scrollRef.current?.scrollLeft < offsetLeftMinusPadding) {
          scrollRef.current?.scrollTo({
            left: offsetLeftMinusPadding,
            behavior: "smooth",
          });
          break;
        }
      }
    }
  }, [scrollRef]);

  useEffect(() => {
    if (scrollRef.current) {
      setCanScrollLeft(scrollRef.current.scrollLeft > 0);
      setCanScrollRight(
        scrollRef.current.scrollLeft + scrollRef.current.clientWidth <
          scrollRef.current.scrollWidth,
      );
      setScrollable(
        scrollRef.current.scrollWidth > scrollRef.current.clientWidth,
      );
    }
  }, [layout]);

  return (
    <div className="flex">
      {/* Icon/Scroll left */}
      <div className="relative w-8 shrink-0">
        <FontAwesomeIcon
          className={`${
            canScrollLeft ? "opacity-0" : "opacity-100"
          } absolute left-0 top-0 mx-1 h-6 w-6 text-purple-500 transition-opacity dark:text-purple-400`}
          icon={light("globe")}
        />

        {/* Scroll left */}
        {scrollable && (
          <button
            aria-label="Scroll left"
            className={`${
              canScrollLeft ? "opacity-100" : "pointer-events-none opacity-0"
            } absolute left-0 top-0 h-6 w-6 rounded text-purple-500 transition-opacity focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500/50 dark:text-purple-400 dark:focus-visible:ring-purple-400/50`}
            onClick={scrollLeft}
            tabIndex={canScrollLeft ? 0 : -1}
            type="button"
          >
            <FontAwesomeIcon className="h-6 w-6" icon={light("angle-left")} />
          </button>
        )}

        <span className="sr-only">
          {canScrollLeft ? t("scrollLeft") : t("worlds.title")}
        </span>
      </div>

      {/* Scrollable area */}
      <ul
        className="chips relative flex h-6 grow gap-1 overflow-x-auto overflow-y-hidden"
        onScroll={() => {
          setCanScrollLeft(
            scrollRef.current ? scrollRef.current.scrollLeft > 0 : false,
          );
          setCanScrollRight(
            scrollRef.current
              ? scrollRef.current.scrollLeft +
                  scrollRef.current.clientWidth +
                  1 <
                  scrollRef.current.scrollWidth
              : false,
          );
        }}
        ref={scrollRef}
      >
        {[...worlds]
          .sort((a, b) => t(`worlds.${a}`).localeCompare(t(`worlds.${b}`)))
          .sort((a, b) => {
            if (filteredWorlds.includes(a) === filteredWorlds.includes(b)) {
              return 0;
            } else {
              return filteredWorlds.includes(a) ? -1 : 1;
            }
          })
          .map((world) => (
            <Chip
              key={world}
              checked={filteredWorlds.includes(world)}
              filter={{ kind: Kind.Worlds, value: world }}
            ></Chip>
          ))}
      </ul>

      {/* Scroll right */}
      {scrollable && (
        <button
          aria-label="Scroll right"
          className={`${
            canScrollRight ? "opacity-100" : "pointer-events-none opacity-0"
          } h-6 w-6 shrink-0 rounded text-purple-500 transition-opacity focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500/50 dark:text-purple-400 dark:focus-visible:ring-purple-400/50`}
          type="button"
          onClick={scrollRight}
        >
          <FontAwesomeIcon className="h-6 w-6" icon={light("angle-right")} />
        </button>
      )}
    </div>
  );
};

export default memo(EmojiWorlds);

import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { ErrorBoundary } from "@sentry/react";
import axios from "axios";
import { capitalize } from "lodash";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useSWR, { KeyedMutator } from "swr";
import { Layout } from "../../constants/enums/Layout";
import { Sort } from "../../constants/enums/Sort";
import Emoji from "../../constants/interfaces/Emoji";
import { emojisCountChanged } from "../../store/EmojisSlice";
import { filtersCleared } from "../../store/FiltersSlice";
import { useAppDispatch, useAppSelector } from "../../store/Hooks";
import { loadingChanged } from "../../store/LoadingSlice";
import Card from "../Card/Card";
import Checkbox from "../Checkbox/Checkbox";
import ErrorBoundaryFallback from "../ErrorBoundaryFallback/ErrorBoundaryFallback";
import Grid from "../Grid/Grid";
import Input from "../Input/Input";
import Level from "../Level/Level";
import Loading from "../Loading/Loading";

const Results = () => {
  const [token, setToken] = useState<string | undefined>();
  const { getToken, isAuthenticated, login, user } = useKindeAuth();
  const { t } = useTranslation();
  const methods = useForm<any>({
    defaultValues: { level: 0, selected: [] },
  });
  const headerHeight = useAppSelector((state) => state.settings.headerHeight);
  const layoutMethod = useAppSelector((state) => state.settings.layout);
  const layoutClass =
    layoutMethod === Layout.List
      ? "grid grid-cols-1 gap-1 p-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
      : "grid grid-cols-[repeat(auto-fill,_minmax(80px,_max-content))] justify-center gap-4 px-1 py-2";
  const sort = useAppSelector((state) => state.settings.sort);
  const urlSearchParams = new URLSearchParams(window.location.search);

  if (sort !== Sort.Name) {
    urlSearchParams.set("sort", sort);
  }

  const fetcher = async (url: string) => {
    return axios
      .get(
        url,
        token
          ? {
              headers: { Authorization: `Bearer ${token}` },
            }
          : undefined,
      )
      .then((res) => res.data);
  };
  const {
    data: emojis,
    isLoading,
    mutate,
  }: { data: Emoji[]; isLoading: any; mutate: KeyedMutator<any> } = useSWR(
    `${process.env.REACT_APP_API_ENDPOINT}/emojis/${
      urlSearchParams ? "?" + urlSearchParams : null
    }`,
    fetcher,
  );
  const [firstLetterOfEmojiNames, setFirstLetterOfEmojiNames] =
    useState<Array<string>>();
  const buildLetterNavigation = useCallback(
    (array: Array<Emoji>) => {
      setFirstLetterOfEmojiNames([
        ...Array.from(
          new Set(
            array
              ?.filter((emoji) => !emoji.favourite)
              ?.map((emoji) => t(`emojis.${emoji._id}`).charAt(0))
              ?.map((value, index, arr) =>
                parseInt(value)
                  ? "#"
                  : arr[index - 1]?.localeCompare(value, "en", {
                        sensitivity: "base",
                      }) !== 0
                    ? value
                    : "",
              ),
          ),
        ),
      ]);
    },
    [t],
  );
  const [filteredAndSortedEmojis, setFilteredAndSortedEmojis] =
    useState(emojis);
  const filters = useAppSelector((state) => state.filters);
  const dispatch = useAppDispatch();
  const bulkRef = useRef<HTMLDivElement>(null);
  const handleLevel = (level: number) => {
    methods.setValue("level", level);
    onSubmit(methods.getValues());
  };
  const onSubmit = async (formData: {
    selected: { emoji: string; maxLevel: number };
    level: number;
  }) => {
    if (isAuthenticated) {
      const accessToken = await getToken();

      axios
        .put(
          `${process.env.REACT_APP_API_ENDPOINT}/levels`,
          {
            ...formData,
            _id: user?.id,
          },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          },
        )
        .then(() => {
          mutate();
          methods.reset();
        });
    } else {
      login();
    }
  };
  const renderCards = () =>
    filteredAndSortedEmojis?.length > 0 ? (
      <div
        className="relative"
        style={{
          top:
            headerHeight +
            (bulkRef.current ? bulkRef.current?.offsetHeight : 0),
        }}
      >
        <FormProvider {...methods}>
          <form>
            <Input type="hidden" name="level" register={methods.register} />

            <div
              className="fixed z-10 flex w-full items-center justify-between gap-2 border-b border-slate-100/50 bg-slate-200/50 p-1 text-sm text-slate-900 shadow-xl backdrop-blur backdrop-filter dark:border-slate-800 dark:bg-slate-900/50 dark:text-slate-100"
              ref={bulkRef}
              style={{ top: headerHeight }}
            >
              <div className="relative flex items-center gap-1">
                <div className="text-slate-600 dark:text-slate-300">
                  {t("bulkChange", {
                    count: methods.watch("selected").length,
                  })}
                </div>
                <Level
                  arrayTo={Math.max(
                    ...filteredAndSortedEmojis.map(
                      (emoji) => emoji.maxLevel + 1,
                    ),
                  )}
                  disabled={!methods.watch("selected").length}
                  highlightCurrent={false}
                  level={methods.watch("level")}
                  onClick={handleLevel}
                ></Level>
              </div>

              <Checkbox
                checked={
                  methods.watch("selected").length ===
                  filteredAndSortedEmojis.filter(
                    (emoji) =>
                      emoji.availableFrom &&
                      new Date(emoji.availableFrom) < new Date(),
                  ).length
                }
                className="flex-row-reverse pr-3 text-sm"
                disabled={layoutMethod === Layout.Grid}
                id="selectAll"
                indeterminate={
                  methods.watch("selected").length > 0 &&
                  methods.watch("selected").length <
                    filteredAndSortedEmojis.filter(
                      (emoji) =>
                        emoji.availableFrom &&
                        new Date(emoji.availableFrom) < new Date(),
                    ).length
                }
                label="selectAll"
                name="selectAll"
                onChange={(event) => {
                  if (event.target.checked) {
                    methods.setValue(
                      "selected",
                      filteredAndSortedEmojis
                        .filter(
                          (emoji) =>
                            emoji.availableFrom &&
                            new Date(emoji.availableFrom) < new Date(),
                        )
                        .map((emoji) => {
                          return {
                            emoji: emoji._id,
                            maxLevel: emoji.maxLevel,
                          };
                        }),
                    );
                  } else {
                    methods.setValue("selected", []);
                  }
                }}
              ></Checkbox>
            </div>

            <div className="flex">
              <div className={`${layoutClass} mb-16 w-full`}>
                {filteredAndSortedEmojis.map((emoji: Emoji) =>
                  layoutMethod === Layout.List ? (
                    <Card emoji={emoji} key={emoji._id} />
                  ) : (
                    <Grid emoji={emoji} key={emoji._id} />
                  ),
                )}
              </div>

              {sort === Sort.Name && (
                <>
                  <div className="hidden w-4 shrink-0 portrait:flex lg:landscape:flex"></div>
                  <div
                    className="fixed right-1 top-1/2 hidden -translate-y-1/2 flex-col items-center text-xs text-slate-500 dark:text-slate-300 portrait:flex lg:landscape:flex"
                    style={{ marginTop: headerHeight / 2 }}
                  >
                    {firstLetterOfEmojiNames?.map((letter) => (
                      <button
                        key={letter}
                        onClick={() => {
                          const firstEmojiStartingWithLetter =
                            filteredAndSortedEmojis.find((emoji) =>
                              letter === "#"
                                ? parseInt(t(`emojis.${emoji._id}`).charAt(0))
                                : t(`emojis.${emoji._id}`).startsWith(letter),
                            );

                          if (firstEmojiStartingWithLetter) {
                            document
                              .getElementById(firstEmojiStartingWithLetter._id)
                              ?.scrollIntoView({
                                block: "center",
                                behavior: "smooth",
                              });
                          }
                        }}
                        type="button"
                      >
                        {letter}
                      </button>
                    ))}
                  </div>
                </>
              )}
            </div>
          </form>
        </FormProvider>
      </div>
    ) : (
      <div
        className="relative m-1 rounded border border-red-600 bg-red-500 p-2 text-center text-sm text-white dark:border-red-500 dark:bg-red-400 dark:text-red-900"
        style={{ top: headerHeight }}
      >
        <FontAwesomeIcon
          className="h-6 w-6"
          icon={light("circle-exclamation")}
        />
        <p>
          {t("noResults.pre")}{" "}
          <button
            aria-label={capitalize(t("noResults.button"))}
            className="font-bold underline"
            onClick={() => {
              dispatch(loadingChanged(true));
              dispatch(filtersCleared());
            }}
            type="button"
          >
            {t("noResults.button")}
          </button>{" "}
          {t("noResults.post")}
        </p>
      </div>
    );

  useEffect(() => {
    dispatch(loadingChanged(isLoading));
    setFilteredAndSortedEmojis(emojis);
    dispatch(emojisCountChanged(filteredAndSortedEmojis?.length));

    // Deselect everything and reset level dropdown when changing filters
    methods.reset();

    // Build letter navigation if sorting by name
    if (sort === Sort.Name) {
      buildLetterNavigation(emojis);
    }

    // Update results if user is authenticated so that levels/favourites display in their correct state
    const getTokenIfAuthed = async () => {
      if (isAuthenticated) {
        await getToken().then((res) => {
          setToken(res);
          mutate();
        });
      }
    };

    getTokenIfAuthed();
  }, [
    buildLetterNavigation,
    dispatch,
    emojis,
    filteredAndSortedEmojis?.length,
    filters,
    getToken,
    isAuthenticated,
    isLoading,
    methods,
    mutate,
    sort,
    token,
  ]);

  return (
    <ErrorBoundary
      fallback={({ error, componentStack }) => (
        <ErrorBoundaryFallback
          error={error as Error}
          componentStack={componentStack}
        />
      )}
    >
      {!filteredAndSortedEmojis || isLoading ? <Loading /> : renderCards()}
    </ErrorBoundary>
  );
};

export default memo(Results);

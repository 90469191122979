import { useTranslation } from "react-i18next";
import { ObjectWithArrayOfNumbers } from "../constants/types/ObjectWithArrayOfNumbers";

export const calculateLevels = (
  array: Array<any>,
  records: Partial<Record<any, number[]>>,
) => {
  const selected = array?.map((entry) => records[entry]);

  if (selected?.length) {
    const levels: Array<number> | undefined = selected.reduce(
      (
        previousValue: number[] | undefined,
        currentValue: number[] | undefined,
      ) =>
        previousValue?.filter((level: number) => currentValue?.includes(level)),
    );

    return levels?.length ? levels : undefined;
  }
};

export const useFormatArray = (array: Array<any> | undefined) => {
  const { t } = useTranslation();

  if (array) {
    return array?.length > 1
      ? `${array?.slice(0, -1).join(", ")} ${t("and")} ${array?.slice(-1)}`
      : array?.toString();
  }
};

export const useFormatLevels = (
  records: ObjectWithArrayOfNumbers,
  maxLevel: number,
  array: string[],
) => {
  // Get levels for all matching powers, and return an array of common levels
  const { t } = useTranslation();
  const levels = calculateLevels(array, records);
  const formattedArray = useFormatArray(levels);

  if (levels) {
    if (
      // If there are more than 1 level and the numbers are consecutive
      levels.length > 1 &&
      levels.every(
        (level: number, index: number) =>
          (levels[index + 1] || level + 1) - level === 1,
      )
    ) {
      if (levels.length === maxLevel) {
        // If the numbers of levels is the same as the emoji's max. level, then return "All"
        return t("all");
      } else {
        // Else return a range of numbers, lowest to highest separated by a hyphen
        return `${levels[0]}-${levels[levels.length - 1]}`;
      }
    } else {
      // Return a formatted array if only 1 level, or the levels are not consecutive
      return formattedArray;
    }
  }
};

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

interface DialogCloseButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
}

const DialogCloseButton = ({ className, ...props }: DialogCloseButtonProps) => {
  const { t } = useTranslation();

  return (
    <button
      {...props}
      aria-label={t('close')}
      className={`${
        className ?? ''
      } h-6 rounded text-slate-500 transition-colors focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500/50 dark:text-slate-400 dark:focus-visible:ring-purple-400/50`}
      type="button"
    >
      <FontAwesomeIcon className="h-6 w-6" icon={light('xmark')} />
    </button>
  );
};

export default DialogCloseButton;

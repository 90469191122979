import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router";
import { Link, ScrollRestoration, useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import Loading from "../../components/Loading/Loading";

function Admin() {
  const { getPermission, isAuthenticated, isLoading, logout } = useKindeAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !getPermission("admin")?.isGranted) {
      navigate("/");
    }
  }, [getPermission, isAuthenticated, isLoading, navigate]);

  if (isLoading) {
    return <Loading />;
  }

  if (isAuthenticated && getPermission("admin").isGranted) {
    return (
      <>
        <div className="fixed left-0 top-0 z-20 w-full border-b border-purple-400 bg-purple-500 p-2 shadow-sm transition-all dark:border-purple-500 dark:bg-purple-600">
          <div className="flex justify-between">
            {/* Logo */}
            <Link className="flex items-center gap-2" to={"/admin"}>
              <div className="flex h-9 w-9 items-center justify-center rounded shadow-sm bg-gradient-crystal-to-tl">
                <FontAwesomeIcon
                  className="h-6 w-6 text-white"
                  icon={solid("wand-sparkles")}
                />
              </div>
              <h1 className="text-xl font-bold text-white dark:text-slate-100">
                DEB Wizard Admin
              </h1>
            </Link>

            {/* Sign Out Button */}
            <Button
              className="border-0 !bg-white py-1 !text-purple-500 hover:!bg-purple-100 focus-visible:!ring-white/50 disabled:hover:bg-white dark:!bg-slate-100 dark:!text-purple-600 hover:dark:!bg-purple-200 dark:focus-visible:!ring-slate-100/50 disabled:dark:hover:!bg-slate-100"
              label={t("signOut")}
              onClick={logout as any}
            >
              <FontAwesomeIcon
                className="h-6 w-6"
                icon={solid("circle-user")}
              />
              {t("signOut")}
            </Button>
          </div>
        </div>

        <div className="p-2 pt-16">
          <ScrollRestoration />
          <Outlet />
        </div>
      </>
    );
  }

  return <></>
}

export default Admin;

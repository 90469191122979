import { createSlice } from '@reduxjs/toolkit';
import Modal from '../constants/interfaces/Modal';

const initialState: Modal = {
  isOpen: false,
};

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    feedbackOpenChanged: (state, action) => {
      state.isOpen = action.payload;
    },
  },
});

export const { feedbackOpenChanged } = feedbackSlice.actions;

export default feedbackSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  count: 0,
};

export const emojisSlice = createSlice({
  name: 'emojis',
  initialState,
  reducers: {
    emojisCountChanged: (state, action) => ({
      ...state,
      count: action.payload,
    }),
  },
});

export const { emojisCountChanged } = emojisSlice.actions;

export default emojisSlice.reducer;

import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Triggers from "../../constants/interfaces/Triggers";

type EmojiTriggersProps = {
  level?: number;
  showHeader?: boolean;
  toTriggerAverage?: number;
  triggers?: Triggers;
};

const EmojiTriggers = ({
  level,
  showHeader,
  toTriggerAverage,
  triggers,
}: EmojiTriggersProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "triggers" });
  const hasFirstActivationValues = useCallback((triggers: Triggers) => {
    return triggers?.toFirstTrigger && triggers?.toFirstTrigger.length > 0;
  }, []);

  return (
    <>
      {triggers && (
        <div className={`${showHeader && "w-full"} flex`}>
          <FontAwesomeIcon
            className="mx-1 h-6 w-6 shrink-0 text-purple-500 dark:text-purple-400"
            icon={light("bullseye-arrow")}
          />
          {!showHeader && <span className="sr-only">{t("title")}</span>}
          <div className={`${showHeader && "w-full"} pl-2`}>
            {showHeader && (
              <p className="py-0.5 text-sm font-bold text-purple-500 dark:text-purple-400">
                {t("title")}
              </p>
            )}
            <table
              className={`${
                showHeader && "w-full"
              } text-left text-xs text-slate-500 dark:text-slate-300`}
            >
              <thead className="border-b-2 border-b-current">
                <tr>
                  <th
                    className="p-1 pl-0"
                    colSpan={hasFirstActivationValues(triggers) ? 2 : 1}
                    scope="row"
                  >
                    {t("level")}
                  </th>
                  {triggers.toTrigger.map((_: number, index: number) => (
                    <th
                      className="p-1 text-right"
                      key={`triggersHeader_${index + 1}`}
                      scope="col"
                    >
                      {index + 1}
                    </th>
                  ))}
                  <th className="p-1 pr-0 text-right" scope="col">
                    {t("average")}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th
                    className="p-1 pl-0 align-top"
                    rowSpan={hasFirstActivationValues(triggers) ? 2 : 1}
                  >
                    {t("trigger")}
                  </th>
                  {hasFirstActivationValues(triggers) && (
                    <th
                      className="p-1"
                      dangerouslySetInnerHTML={{
                        __html: t("first"),
                      }}
                      scope="row"
                    ></th>
                  )}
                  {hasFirstActivationValues(triggers)
                    ? triggers.toFirstTrigger?.map(
                        (trigger: number, index: number) => (
                          <td
                            className="p-1 text-right tabular-nums"
                            key={`triggersRow_1st_${index + 1}`}
                          >
                            {trigger}
                          </td>
                        ),
                      )
                    : triggers.toTrigger.map(
                        (trigger: number, index: number) => (
                          <td
                            className={`p-1 text-right tabular-nums ${level === index + 1 ? "font-bold text-purple-500 dark:text-purple-400" : ""}`}
                            key={`triggersRow_all_${index + 1}`}
                          >
                            {trigger}
                          </td>
                        ),
                      )}
                  {hasFirstActivationValues(triggers) ? (
                    <td className="p-1 text-right tabular-nums">&mdash;</td>
                  ) : (
                    <td
                      className={`p-1 text-right tabular-nums ${!level ? "font-bold text-purple-500 dark:text-purple-400" : ""}`}
                    >
                      {toTriggerAverage}
                    </td>
                  )}
                </tr>
                {hasFirstActivationValues(triggers) && (
                  <tr>
                    <th
                      className="p-1 tabular-nums"
                      dangerouslySetInnerHTML={{
                        __html: t("second"),
                      }}
                      scope="row"
                    ></th>
                    {triggers.toTrigger.map(
                      (trigger: number, index: number) => (
                        <td
                          className={`p-1 text-right tabular-nums ${level === index + 1 ? "font-bold text-purple-500 dark:text-purple-400" : ""}`}
                          key={`triggersRow_2nd+_${index + 1}`}
                        >
                          {trigger}
                        </td>
                      ),
                    )}
                    <td
                      className={`p-1 text-right tabular-nums ${!level ? "font-bold text-purple-500 dark:text-purple-400" : ""}`}
                    >
                      {toTriggerAverage}
                    </td>
                  </tr>
                )}
                {triggers.toOvercharge && triggers.toOvercharge.length > 0 && (
                  <tr>
                    <th
                      className="p-1 pl-0 text-amber-600 dark:text-amber-400"
                      colSpan={hasFirstActivationValues(triggers) ? 2 : 1}
                      scope="row"
                    >
                      {t("overcharge")}
                    </th>
                    {triggers.toOvercharge.map(
                      (overcharge: number, index: number) => (
                        <td
                          className="p-1 text-right tabular-nums text-amber-600 dark:text-amber-400"
                          key={`triggersRow_overcharge_${index + 1}`}
                        >
                          {overcharge}
                        </td>
                      ),
                    )}
                    <td className="p-1 text-right text-amber-600 dark:text-amber-400">
                      &mdash;
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default EmojiTriggers;

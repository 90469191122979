import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useSWR, { KeyedMutator } from "swr";
import Button from "../../../components/Button/Button";
import DangerButton from "../../../components/DangerButton/DangerButton";
import Version from "../../../constants/interfaces/Version";

const AdminVersions = () => {
  const { getToken } = useKindeAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const fetcher = (url: string) => axios.get(url).then((res) => res.data);

  const {
    data,
    isLoading,
    mutate,
  }: { data: Version[]; isLoading: boolean; mutate: KeyedMutator<any> } =
    useSWR(`${process.env.REACT_APP_API_ENDPOINT}/versions`, fetcher);

  const deleteVersion = async (id: string) => {
    const accessToken = await getToken();

    if (
      window.confirm(t("admin.areYouSure", { value: t("versions.version") }))
    ) {
      await axios
        .delete(`${process.env.REACT_APP_API_ENDPOINT}/versions/${id}`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(() => {
          mutate();
        });
    }
  };

  return (
    <>
      <div className="mb-4 flex gap-2">
        <FontAwesomeIcon
          className="h-7 w-7 text-purple-500 dark:text-purple-400"
          icon={light("hashtag")}
        />

        <h1 className="text-xl font-bold text-slate-900 dark:text-slate-100">
          {t("versions.title")}
        </h1>
      </div>

      <Button
        className="flex w-full gap-2"
        label={`${t("add")} ${t("versions.version")}`}
        onClick={() => navigate("/admin/versions/add")}
      >
        <FontAwesomeIcon
          className="h-5 w-5"
          icon={light("add")}
        ></FontAwesomeIcon>
        {t("add")} {t("versions.version")}
      </Button>

      {!isLoading && !data?.length ? (
        <div className="relative mt-4 rounded border border-yellow-600 bg-yellow-500 p-2 text-center text-sm text-white dark:border-yellow-500 dark:bg-yellow-400 dark:text-yellow-900">
          <FontAwesomeIcon
            className="h-6 w-6"
            icon={light("square-dashed-circle-plus")}
          />
          <p>{t("admin.empty", { value: t("versions.title") })}</p>
        </div>
      ) : (
        <table className="w-full text-slate-600 dark:text-slate-300">
          <thead className="border-b-2 border-b-current">
            <tr>
              <th className="p-2 text-left" scope="col">
                {t("versions.version")}
              </th>
              <th className="hidden p-2 text-left sm:table-cell" scope="col">
                {t("versions.date")}
              </th>
              <th className="w-[38px] p-2" scope="col"></th>
              <th className="w-[38px] pl-2" scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {data?.length
              ? data?.map((version) => {
                  return (
                    <tr
                      className="even:bg-slate-200/50 even:dark:bg-slate-800/50"
                      key={version._id}
                    >
                      <th className="rounded-l p-2 text-left" scope="row">
                        {version.version}
                      </th>
                      <td className="hidden p-2 sm:table-cell">
                        {new Date(version.date).toLocaleDateString(undefined, {
                          timeZone: "UTC",
                        })}
                      </td>
                      <td className="p-2">
                        <Button
                          label={`${t("edit")} ${t("versions.version")}`}
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(`/admin/versions/edit/${version?._id}`);
                          }}
                        >
                          <FontAwesomeIcon
                            className="h-5 w-5"
                            icon={light("pencil")}
                          ></FontAwesomeIcon>
                        </Button>
                      </td>
                      <td className="rounded-r pl-2">
                        <DangerButton
                          onClick={(e) => {
                            e.preventDefault();
                            deleteVersion(version?._id!);
                          }}
                        ></DangerButton>
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      )}
    </>
  );
};

export default AdminVersions;

import { createSlice } from "@reduxjs/toolkit";
import Plausible from "plausible-tracker";
import { Layout } from "../constants/enums/Layout";
import { Sort } from "../constants/enums/Sort";
import { Theme } from "../constants/enums/Theme";
import Settings from "../constants/interfaces/Settings";

let searchParams: URLSearchParams = new URLSearchParams(window.location.search);

const { trackEvent } = Plausible();
const initialState: Settings = {
  headerHeight: 157,
  layout: localStorage.getItem("layout") ?? Layout.List,
  showBlitzModifiers: JSON.parse(
    localStorage.getItem("showBlitzModifiers") ?? "true",
  ),
  showGroups: JSON.parse(localStorage.getItem("showGroups") ?? "true"),
  showPowers: JSON.parse(localStorage.getItem("showPowers") ?? "true"),
  showRecommendations: JSON.parse(
    localStorage.getItem("showRecommendations") ?? "true",
  ),
  showTags: JSON.parse(localStorage.getItem("showTags") ?? "true"),
  showTriggers: JSON.parse(localStorage.getItem("showTriggers") ?? "true"),
  showWorlds: JSON.parse(localStorage.getItem("showWorlds") ?? "true"),
  sort:
    (searchParams.has("sort")
      ? searchParams.get("sort")
      : localStorage.getItem("sort")) ?? Sort.Name,
  theme: localStorage.getItem("theme") ?? "auto",
};

const historyPushState = () => {
  const newRelativePathQuery =
    window.location.pathname +
    (Array.from(searchParams).length ? "?" + searchParams.toString() : "");

  window.history.pushState(null, "", newRelativePathQuery);
};
const setDarkTheme = () => {
  document.documentElement.classList.add("dark");
  document
    .querySelector('meta[name="theme-color"]')
    ?.setAttribute("content", "#9333ea");
};
const setLightTheme = () => {
  document.documentElement.classList.remove("dark");
  document
    .querySelector('meta[name="theme-color"]')
    ?.setAttribute("content", "#a855f7");
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    headerHeightChanged: (state, action) => {
      return {
        ...state,
        headerHeight: action.payload,
      };
    },
    layoutChanged: (state, action) => {
      localStorage.setItem("layout", action.payload);

      return {
        ...state,
        layout: action.payload,
      };
    },
    settingsChanged: (state, action) => {
      localStorage.setItem(
        action.payload.name,
        action.payload.checked.toString(),
      );

      return {
        ...state,
        [action.payload.name]: action.payload.checked,
      };
    },
    sortChanged: (state, action) => {
      searchParams = new URLSearchParams(window.location.search);
      localStorage.setItem("sort", action.payload);
      searchParams.set("sort", action.payload);

      trackEvent("Sort", {
        props: { selection: action.payload },
      });

      historyPushState();

      return {
        ...state,
        sort: action.payload,
      };
    },
    sortParamChanged: (state) => {
      searchParams = new URLSearchParams(window.location.search);
      localStorage.setItem("sort", searchParams.get("sort") ?? Sort.Name);

      return {
        ...state,
        sort: (searchParams.get("sort") as Sort) ?? Sort.Name,
      };
    },
    themeChanged: (state, action) => {
      localStorage.setItem("theme", action.payload);

      switch (action.payload) {
        case Theme.Auto:
          window.matchMedia("(prefers-color-scheme: dark)").matches
            ? setDarkTheme()
            : setLightTheme();
          break;

        case Theme.Light:
          setLightTheme();
          break;

        case Theme.Dark:
          setDarkTheme();
          break;
      }

      return { ...state, theme: action.payload };
    },
  },
});

export const {
  headerHeightChanged,
  layoutChanged,
  settingsChanged,
  sortChanged,
  sortParamChanged,
  themeChanged,
} = settingsSlice.actions;

export default settingsSlice.reducer;

import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Control, Controller, FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select, {
  ClearIndicatorProps,
  DropdownIndicatorProps,
  OptionProps,
  Props,
  StylesConfig,
  components,
} from "react-select";
import tw from "twin.macro";

interface SingleSelectProps
  extends Props<{ label: number | string; value: number | string }, false> {
  control: Control<FieldValues, any>;
  label?: string;
  name: string;
  onChange?: any;
  options: { label: number | string; value: number | string }[] | undefined;
}

const styles: StylesConfig<
  { label: number | string; value: number | string },
  false
> = {
  clearIndicator: (provided) => ({
    ...provided,
    ...tw`p-[7px] text-slate-900 transition-colors hover:text-purple-500 dark:text-slate-100 dark:hover:text-purple-400`,
  }),
  control: (provided, state) => ({
    ...provided,
    ...(state.isFocused
      ? tw`ring-2 ring-purple-500/50 dark:ring-purple-400/50`
      : ``),
    ...tw`min-h-[30px] rounded border-slate-300 bg-white shadow-none hover:border-slate-300 dark:border-slate-600 dark:bg-slate-800 dark:hover:border-slate-600`,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    ...tw`p-[7px] text-slate-900 transition-colors hover:text-purple-500 dark:text-slate-100 dark:hover:text-purple-400`,
  }),
  groupHeading: (provided) => ({
    ...provided,
    height: 18,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    ...tw`bg-slate-400`,
  }),
  input: (provided) => ({
    ...provided,
    ...tw`text-slate-900 dark:text-slate-100 text-sm my-0`,
  }),
  menu: (provided) => ({
    ...provided,
    ...tw`mt-1 translate-y-1 rounded border border-slate-200 bg-white shadow-xl transition duration-200 dark:border-slate-600 dark:bg-slate-700`,
  }),
  option: (provided, state) => ({
    ...provided,
    ...(state.isFocused ? tw`bg-purple-500/20 dark:bg-purple-400/20` : ``),
    ...(state.isSelected
      ? tw`light:text-slate-100 !bg-purple-500 dark:!bg-purple-400`
      : ``),
    ...tw`text-slate-900 dark:text-slate-100 hover:bg-purple-500/20 dark:hover:bg-purple-400/20`,
    height: 40,
  }),
  placeholder: (provided) => ({
    ...provided,
    ...tw`text-slate-400`,
  }),
  singleValue: (provided) => ({
    ...provided,
    ...tw`text-sm text-slate-900 dark:text-slate-100`,
  }),
  valueContainer: (provided) => ({
    ...provided,
    ...tw`gap-2 px-[12px] py-[6px]`,
  }),
};

const ClearIndicator = (
  props: ClearIndicatorProps<
    { label: number | string; value: number | string },
    false
  >,
) => (
  <components.ClearIndicator {...props}>
    <FontAwesomeIcon className="h-4 w-4" icon={light("xmark")} />
  </components.ClearIndicator>
);
const DropdownIndicator = (
  props: DropdownIndicatorProps<
    { label: number | string; value: number | string },
    false
  >,
) => (
  <components.DropdownIndicator {...props}>
    <FontAwesomeIcon className="h-4 w-4" icon={light("chevron-down")} />
  </components.DropdownIndicator>
);
const Option = (
  props: OptionProps<{ label: number | string; value: number | string }>,
) => {
  delete props.innerProps.onMouseMove;
  delete props.innerProps.onMouseOver;
  return <components.Option {...props}>{props.children}</components.Option>;
};

const SingleSelect = ({
  control,
  label,
  name,
  onChange,
  options,
  ...props
}: SingleSelectProps) => {
  const { t } = useTranslation();

  return (
    <div className={`${props.className} flex flex-col`}>
      {label && (
        <label
          className="text-slate-600 dark:text-slate-300"
          htmlFor={props.inputId}
        >
          {t(label)}{" "}
          {!props.isDisabled && (
            <>
              {props.required ? (
                <span className="text-red-500 dark:text-red-400">*</span>
              ) : (
                <span className="text-sm text-slate-400">
                  ({t("optional")})
                </span>
              )}
            </>
          )}
        </label>
      )}
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Select
            {...field}
            {...props}
            components={{ ClearIndicator, DropdownIndicator, Option }}
            menuPlacement="auto"
            onChange={(event) => {
              field.onChange(event);

              if (onChange) {
                onChange(event);
              }
            }}
            options={options}
            styles={styles}
            value={options?.find((option) => option.value === field.value)}
          />
        )}
        rules={{ required: props.required }}
      />
    </div>
  );
};

export default SingleSelect;

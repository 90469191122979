import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type ErrorBoundaryFallbackProps = {
  error: Error;
  componentStack: string | null;
};

const ErrorBoundaryFallback = ({
  error,
  componentStack,
}: ErrorBoundaryFallbackProps) => {
  return (
    <div className="m-1 rounded border border-red-600 bg-red-500 p-2 text-center text-sm text-white dark:border-red-500 dark:bg-red-400 dark:text-red-900">
      <FontAwesomeIcon className="h-6 w-6" icon={light('circle-exclamation')} />
      <p>{error.toString()}</p>
      <p>{componentStack}</p>
    </div>
  );
};

export default ErrorBoundaryFallback;

import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { lazy, Suspense, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import "./App.scss";
import Header from "./components/Header/Header";
import Loading from "./components/Loading/Loading";
import Results from "./components/Results/Results";
import { feedbackOpenChanged } from "./store/FeedbackSlice";
import { filtersParamsChanged } from "./store/FiltersSlice";
import { useAppDispatch, useAppSelector } from "./store/Hooks";
import { recommendationsOpenChanged } from "./store/RecommendationsSlice";
import { sortParamChanged } from "./store/SettingsSlice";

type AppProps = {
  description?: string;
  urlSearchParams?: URLSearchParams;
  title?: string;
};

const App = ({ description, urlSearchParams, title }: AppProps) => {
  const [_, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const Feedback = lazy(() => import("./components/Feedback/Feedback"));
  const feedbackIsOpen = useAppSelector((state) => state.feedback.isOpen);
  const Recommendations = lazy(
    () => import("./components/Recommendations/Recommendations"),
  );
  const recommendationsIsOpen = useAppSelector(
    (state) => state.recommendations.isOpen,
  );

  window.addEventListener("popstate", () => {
    dispatch(filtersParamsChanged());
    dispatch(sortParamChanged());
  });

  useEffect(() => {
    if (urlSearchParams) {
      setSearchParams(urlSearchParams);
      dispatch(filtersParamsChanged());
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description}></meta>
      </Helmet>

      <Header />

      <Results />

      {/* Recommendations button */}
      <div
        className="fixed bottom-2 right-16 h-[52px] w-[52px] rounded-full shadow-xl"
        data-testid="recommendationsButton"
      >
        <div className="h-full w-full animate-[spin_3s_linear_infinite] rounded-full bg-gradient-crystal-to-br"></div>
        <button
          aria-label={t("recommendations.button")}
          className="absolute left-[2px] top-[2px] z-10 h-12 w-12 rounded-full bg-purple-500 dark:bg-purple-600"
          onClick={() => dispatch(recommendationsOpenChanged(true))}
          type="button"
        >
          <FontAwesomeIcon
            className="text-2xl text-white dark:text-slate-100"
            icon={light("lightbulb-exclamation")}
          />
        </button>
      </div>

      {/* Recommendations modal */}
      {recommendationsIsOpen && (
        <Suspense fallback={<Loading />}>
          <Recommendations />
        </Suspense>
      )}

      {/* Feedback button */}
      <button
        aria-label={t("feedback.button")}
        className="fixed bottom-2 right-2 m-[2px] h-12 w-12 rounded-full bg-purple-500 shadow-xl dark:bg-purple-600"
        onClick={() => dispatch(feedbackOpenChanged(true))}
        type="button"
      >
        <FontAwesomeIcon
          className="text-2xl text-white dark:text-slate-100"
          icon={light("envelope")}
        />
      </button>

      {/* Feedback modal */}
      {feedbackIsOpen && (
        <Suspense fallback={<Loading />}>
          <Feedback />
        </Suspense>
      )}
    </>
  );
};

export default App;

import { InputHTMLAttributes, useEffect, useRef } from "react";
import { UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  checked?: boolean;
  className?: string;
  indeterminate?: boolean;
  label?: string;
  name: string;
  register?: UseFormRegister<any>;
}

const Checkbox = ({
  checked = false,
  className,
  indeterminate = false,
  label,
  name,
  register,
  ...props
}: CheckboxProps) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (ref?.current) {
      ref.current.indeterminate = indeterminate;
    }
  }, [indeterminate, ref]);

  return (
    <div className={`${className} flex items-center justify-center gap-2`}>
      {register ? (
        <input
          {...props}
          {...register(name, { required: props.required })}
          className="form-checkbox rounded border-slate-400 bg-white text-purple-500 shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-500/50 focus:ring-offset-0 disabled:!opacity-30 dark:border-slate-500 dark:bg-slate-900 dark:text-purple-400 checked:dark:border-transparent checked:dark:bg-current indeterminate:dark:border-transparent indeterminate:dark:bg-current dark:focus:ring-purple-400/50"
          type="checkbox"
        ></input>
      ) : (
        <input
          {...props}
          checked={checked}
          className="form-checkbox rounded border-slate-400 bg-white text-purple-500 shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-500/50 focus:ring-offset-0 disabled:!opacity-30 dark:border-slate-500 dark:bg-slate-900 dark:text-purple-400 checked:dark:border-transparent checked:dark:bg-current indeterminate:dark:border-transparent indeterminate:dark:bg-current dark:focus:ring-purple-400/50"
          ref={ref}
          type="checkbox"
        ></input>
      )}

      {label && (
        <label
          className="gap-2 text-slate-600 dark:text-slate-300"
          htmlFor={props.id}
        >
          {t(label)}
        </label>
      )}
    </div>
  );
};

export default Checkbox;

import { duotone, light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IKImage } from "imagekitio-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Theme } from "../../constants/enums/Theme";

type EmojiNameProps = {
  box: string;
  filePath?: string;
  id: string;
  showEffects?: boolean;
};

const EmojiName = ({ box, filePath, id, showEffects }: EmojiNameProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "emojis" });
  const [currentTheme, setCurrentTheme] = useState<Theme>();
  const boxClassNames = {
    silver: "before:bg-gradient-silver-to-t",
    gold: "before:bg-gradient-gold-to-t",
    series1: "before:bg-gradient-gold-to-t",
    series2: "before:bg-gradient-gold-to-t",
    series3: "before:bg-gradient-gold-to-t",
    story: "before:bg-gradient-story-to-t",
    rainbow: "before:bg-gradient-rainbow-to-br",
    villain: "before:bg-gradient-villain-to-t",
    crystal: "before:bg-gradient-crystal-to-br",
    platinum: "before:bg-gradient-platinum-to-tr",
    cozyCrafts: "before:bg-gradient-cozy-crafts-to-tr",
    stainedGlass: "before:bg-gradient-stained-glass-emoji-border-to-t",
  };
  const mql = window.matchMedia("(prefers-color-scheme: dark)");

  useEffect(() => {
    setCurrentTheme(() => {
      if (
        localStorage.getItem("theme") === Theme.Dark ||
        ((!localStorage.getItem("theme") ||
          localStorage.getItem("theme") === Theme.Auto) &&
          mql.matches)
      ) {
        return Theme.Dark;
      } else {
        return Theme.Light;
      }
    });

    mql.onchange = () => {
      setCurrentTheme(localStorage.getItem("theme") as Theme);
    };
  }, [mql]);

  return (
    <>
      <div
        className={`${boxClassNames[box as keyof typeof boxClassNames]} ${
          box === "stainedGlass"
            ? "outline -outline-offset-[3px] outline-blue-950"
            : null
        } emoji-border relative h-8 w-8 shrink-0 rounded-lg bg-gradient-radial from-white to-cyan-300 p-1 before:absolute before:inset-0 before:rounded-lg before:p-0.5 before:content-[''] dark:from-cyan-200 dark:to-cyan-500 dark:before:border-opacity-90`}
      >
        {filePath ? (
          <IKImage
            alt={t(id)}
            className="relative"
            height="24"
            loading={showEffects ? "lazy" : undefined}
            lqip={{ active: true }}
            path={filePath}
            transformation={[
              {
                di:
                  currentTheme === Theme.Dark ? "dark-error.png" : "error.png",
                dpr: window.devicePixelRatio.toString(),
                width: "24",
                height: "24",
                cropMode: "pad_resize",
              },
            ]}
            urlEndpoint={process.env.REACT_APP_IMAGEKIT_URL_ENDPOINT}
            width="24"
          />
        ) : (
          <FontAwesomeIcon
            icon={light("face-dotted")}
            size="lg"
            className="mx-auto p-0.5 text-cyan-900 opacity-50 dark:text-cyan-100"
          />
        )}
        {(box === "crystal" || box === "platinum") && (
          <FontAwesomeIcon
            className="absolute -right-1 -top-1 animate-[pulse_1s_ease-in-out_infinite] text-white drop-shadow-md"
            icon={duotone("sparkles")}
          ></FontAwesomeIcon>
        )}
      </div>

      <div
        className={`${
          box ? "my-1" : ""
        } break hyphens-auto break-words font-bold text-slate-900 [word-break:break-word] dark:text-slate-100`}
      >
        {t(id)}
      </div>
    </>
  );
};

export default EmojiName;

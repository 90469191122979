import {
  autoPlacement,
  autoUpdate,
  offset,
  shift,
  useFloating,
} from "@floating-ui/react-dom";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Ban } from "../../constants/enums/Ban";
import Emoji from "../../constants/interfaces/Emoji";

type EmojiFlairProps = {
  emoji: Emoji;
};

const EmojiFlair = ({ emoji }: EmojiFlairProps) => {
  const { t } = useTranslation();
  const { refs, floatingStyles } = useFloating({
    middleware: [autoPlacement(), offset(4), shift()],
    whileElementsMounted: autoUpdate,
  });
  const emojiAnniversary =
    emoji.availableFrom &&
    new Date(emoji.availableFrom)?.getDate() === new Date().getDate() &&
    new Date(emoji.availableFrom).getMonth() === new Date().getMonth()
      ? new Date().getFullYear() - new Date(emoji.availableFrom).getFullYear()
      : 0;

  return (
    <>
      {(emoji.exclusive ||
        emoji.groupCollectionReward ||
        (emoji.banned && emoji.banned?.length > 0) ||
        emoji.interactivePower ||
        emojiAnniversary > 0 ||
        (emoji.stardustUpgradesAvailableFrom &&
          new Date(emoji.stardustUpgradesAvailableFrom) < new Date())) && (
        <Popover>
          {() => (
            <>
              <Popover.Button
                aria-label="Emoji attributes"
                className="flex h-6 items-center gap-1 rounded px-0.5 text-purple-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500/75 dark:text-purple-400 dark:focus-visible:ring-purple-400/75"
                ref={refs.setReference}
              >
                {/* Exclusive */}
                {emoji.exclusive && <FontAwesomeIcon icon={light("gem")} />}
                {/* Group Collection Reward */}
                {emoji.groupCollectionReward && (
                  <FontAwesomeIcon icon={light("face-smile")} />
                )}
                {/* Interactive Power */}
                {emoji.interactivePower && (
                  <FontAwesomeIcon icon={light("joystick")} />
                )}
                {/* Stardust/Upgrades */}
                {emoji.stardustUpgradesAvailableFrom &&
                  new Date(emoji.stardustUpgradesAvailableFrom) <
                    new Date() && <FontAwesomeIcon icon={light("star-christmas")} />}
                {/* Anniversary */}
                {emojiAnniversary > 0 && (
                  <FontAwesomeIcon icon={light("cake-candles")} />
                )}
                {/* Bans */}
                {/* Food Fight */}
                {emoji.banned?.includes(Ban.FoodFight) && (
                  <span className="fa-layers fa-fw">
                    <FontAwesomeIcon fixedWidth icon={light("soft-serve")} />
                    <FontAwesomeIcon
                      className="text-red-500"
                      fixedWidth
                      icon={light("ban")}
                      transform="grow-6"
                    />
                  </span>
                )}
                {/* Survival */}
                {emoji.banned?.includes(Ban.Survival) && (
                  <span className="fa-layers fa-fw">
                    <FontAwesomeIcon fixedWidth icon={light("bomb")} />
                    <FontAwesomeIcon
                      className="text-red-500"
                      fixedWidth
                      icon={light("ban")}
                      transform="grow-6"
                    />
                  </span>
                )}
                {/* Team */}
                {emoji.banned?.includes(Ban.Team) && (
                  <span className="fa-layers fa-fw">
                    <FontAwesomeIcon fixedWidth icon={light("handshake")} />
                    <FontAwesomeIcon
                      className="text-red-500"
                      fixedWidth
                      icon={light("ban")}
                      transform="grow-6"
                    />
                  </span>
                )}
              </Popover.Button>

              <Popover.Panel
                className="z-30"
                ref={refs.setFloating}
                style={floatingStyles}
              >
                <div className="overflow-hidden rounded shadow-xl ring-1 ring-slate-200 dark:ring-slate-500">
                  <div className="flex flex-row items-center gap-2 bg-white px-2 py-1 text-slate-900 dark:bg-slate-700 dark:text-slate-100">
                    <ul className="fa-ul">
                      {/* Exclusive */}
                      {emoji.exclusive && (
                        <li>
                          <FontAwesomeIcon
                            className="text-purple-500 dark:text-purple-400"
                            fixedWidth
                            icon={light("gem")}
                            listItem
                          />
                          <span className="ml-2 whitespace-nowrap text-sm">
                            <>{t("exclusivity.exclusive")}</>
                          </span>
                        </li>
                      )}

                      {/* Group Collection Reward */}
                      {emoji.groupCollectionReward && (
                        <li>
                          <FontAwesomeIcon
                            className="text-purple-500 dark:text-purple-400"
                            fixedWidth
                            icon={light("face-smile")}
                            listItem
                          />
                          <span className="ml-2 whitespace-nowrap text-sm">
                            <>{t("exclusivity.groupCollectionReward")}</>
                          </span>
                        </li>
                      )}

                      {/* Interactive Power */}
                      {emoji.interactivePower && (
                        <li>
                          <FontAwesomeIcon
                            className="text-purple-500 dark:text-purple-400"
                            fixedWidth
                            icon={light("joystick")}
                            listItem
                          />
                          <span className="ml-2 whitespace-nowrap text-sm">
                            <>{t("flair.interactivePower")}</>
                          </span>
                        </li>
                      )}

                      {/* Stardust/Upgrades */}
                      {emoji.stardustUpgradesAvailableFrom &&
                        new Date(emoji.stardustUpgradesAvailableFrom) <
                          new Date() && (
                          <li>
                            <FontAwesomeIcon
                              className="text-purple-500 dark:text-purple-400"
                              fixedWidth
                              icon={light("star-christmas")}
                              listItem
                            />
                            <span className="ml-2 whitespace-nowrap text-sm">
                              <>{t("flair.stardustUpgrades")}</>
                            </span>
                          </li>
                        )}

                      {/* Anniversary */}
                      {emojiAnniversary > 0 && (
                        <li>
                          <FontAwesomeIcon
                            className="text-purple-500 dark:text-purple-400"
                            fixedWidth
                            icon={light("cake-candles")}
                            listItem
                          />
                          <span className="ml-2 whitespace-nowrap text-sm">
                            <>
                              {t("flair.anniversary", {
                                count: emojiAnniversary,
                              })}
                            </>
                          </span>
                        </li>
                      )}

                      {/* Bans */}
                      {/* Food Fight */}
                      {emoji.banned?.includes(Ban.FoodFight) && (
                        <li>
                          <span className="fa-fw fa-layers fa-li svg-inline--fa">
                            <FontAwesomeIcon
                              className="text-purple-500 dark:text-purple-400"
                              fixedWidth
                              icon={light("soft-serve")}
                              listItem
                            />
                            <FontAwesomeIcon
                              className="text-red-500"
                              fixedWidth
                              icon={light("ban")}
                              listItem
                              transform="grow-6"
                            />
                          </span>
                          <span className="ml-2 whitespace-nowrap text-sm">
                            <>{t("bans.foodFight")}</>
                          </span>
                        </li>
                      )}

                      {/* Survival */}
                      {emoji.banned?.includes(Ban.Survival) && (
                        <li>
                          <span className="fa-fw fa-layers fa-li svg-inline--fa">
                            <FontAwesomeIcon
                              className="text-purple-500 dark:text-purple-400"
                              fixedWidth
                              icon={light("bomb")}
                              listItem
                            />
                            <FontAwesomeIcon
                              className="text-red-500"
                              fixedWidth
                              icon={light("ban")}
                              listItem
                              transform="grow-6"
                            />
                          </span>
                          <span className="ml-2 whitespace-nowrap text-sm">
                            <>{t("bans.survival")}</>
                          </span>
                        </li>
                      )}

                      {/* Team */}
                      {emoji.banned?.includes(Ban.Team) && (
                        <li>
                          <span className="fa-fw fa-layers fa-li svg-inline--fa">
                            <FontAwesomeIcon
                              className="text-purple-500 dark:text-purple-400"
                              fixedWidth
                              icon={light("handshake")}
                              listItem
                            />
                            <FontAwesomeIcon
                              className="text-red-500"
                              fixedWidth
                              icon={light("ban")}
                              listItem
                              transform="grow-6"
                            />
                          </span>
                          <span className="ml-2 whitespace-nowrap text-sm">
                            <>{t("bans.team")}</>
                          </span>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </Popover.Panel>
            </>
          )}
        </Popover>
      )}
    </>
  );
};

export default EmojiFlair;

import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import axios from "axios";
import { camelCase } from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useSWR, { KeyedMutator } from "swr";
import Button from "../../../components/Button/Button";
import DangerButton from "../../../components/DangerButton/DangerButton";
import Input from "../../../components/Input/Input";
import SortableItem from "../../../components/SortableItem/SortableItem";
import DocumentWithPosition from "../../../constants/interfaces/DocumentWithPosition";

const AdminBoxes = () => {
  const [items, setItems] = useState<UniqueIdentifier[]>([]);
  const { getToken } = useKindeAuth();
  const { t } = useTranslation();
  const {
    formState: { isValid },
    handleSubmit,
    register,
    reset,
  } = useForm<DocumentWithPosition>();

  const fetcher = (url: string) =>
    axios.get(url).then((res) => {
      setItems(res.data.map((box: DocumentWithPosition) => box._id));

      return res.data;
    });

  const {
    data,
    isLoading,
    mutate,
  }: {
    data: DocumentWithPosition[];
    isLoading: boolean;
    mutate: KeyedMutator<any>;
  } = useSWR(`${process.env.REACT_APP_API_ENDPOINT}/boxes`, fetcher);

  const onSubmit = async (formData: DocumentWithPosition) => {
    const accessToken = await getToken();
    const newDocument: DocumentWithPosition = {
      _id: camelCase(formData._id),
      position: data.length,
    };

    await axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/boxes`, newDocument, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then(() => {
        reset();
        mutate([...data, newDocument].sort((a, b) => a.position - b.position));
      });
  };

  const patchBoxes = async (items: UniqueIdentifier[]) => {
    const accessToken = await getToken();
    await axios
      .patch(
        `${process.env.REACT_APP_API_ENDPOINT}/boxes`,
        {
          items,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      )
      .then(() => {
        mutate(data.sort((a, b) => (a.position ?? 0) - (b.position ?? 0)));
      });
  };

  const deleteBox = async (id: string) => {
    const accessToken = await getToken();
    if (window.confirm(t("admin.areYouSure", { value: t("boxes.box") }))) {
      await axios
        .delete(`${process.env.REACT_APP_API_ENDPOINT}/boxes/${id}`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(() => {
          mutate();
        });
    }
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setItems((items) => {
        const oldIndex = items?.indexOf(active.id);
        const newIndex = items?.indexOf(over?.id!);

        const patchedData = arrayMove(items, oldIndex, newIndex);

        patchBoxes(patchedData);

        return patchedData;
      });
    }
  };

  return (
    <>
      <div className="mb-4 flex gap-2">
        <FontAwesomeIcon
          className="h-7 w-7 text-purple-500 transition-opacity dark:text-purple-400"
          icon={light("box")}
        />

        <h1 className="text-xl font-bold text-slate-900 dark:text-slate-100">
          {t("boxes.title")}
        </h1>
      </div>

      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <div className="flex gap-2 pr-1">
          <Input className="grow" name="_id" register={register} required />
          <Button disabled={!isValid} label={t("add")} type="submit">
            <FontAwesomeIcon
              className="h-5 w-5"
              icon={light("add")}
            ></FontAwesomeIcon>
          </Button>
        </div>
      </form>

      {!isLoading && !data?.length ? (
        <div className="relative mt-4 rounded border border-yellow-600 bg-yellow-500 p-2 text-center text-sm text-white dark:border-yellow-500 dark:bg-yellow-400 dark:text-yellow-900">
          <FontAwesomeIcon
            className="h-6 w-6"
            icon={light("square-dashed-circle-plus")}
          />
          <p>{t("admin.empty", { value: t("boxes.title") })}</p>
        </div>
      ) : (
        <ol className="mt-4">
          <DndContext
            collisionDetection={closestCenter}
            modifiers={[restrictToVerticalAxis]}
            onDragEnd={handleDragEnd}
            sensors={sensors}
          >
            <SortableContext
              items={items}
              strategy={verticalListSortingStrategy}
            >
              {items?.length
                ? items?.map((item) => {
                    const box = data.find((box) => box._id === item);

                    return (
                      <SortableItem key={box?._id} id={box?._id}>
                        <span className="grow">{t(`boxes.${box?._id}`)}</span>

                        <DangerButton
                          onClick={(e) => {
                            e.preventDefault();
                            deleteBox(box?._id!);
                          }}
                        ></DangerButton>
                      </SortableItem>
                    );
                  })
                : null}
            </SortableContext>
          </DndContext>
        </ol>
      )}
    </>
  );
};

export default AdminBoxes;

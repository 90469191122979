import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const fallbackLanguage = "en";

const projectToken = process.env.REACT_APP_SIMPLELOCALIZE_PROJECT_TOKEN; // YOUR PROJECT TOKEN
const apiKey = process.env.REACT_APP_SIMPLELOCALIZE_API_KEY; // YOUR API KEY, (do not include your API key in production code!)
const cdnBaseUrl = "https://cdn.simplelocalize.io";
const environment = "_latest"; // or "_production"

const loadPath = `${cdnBaseUrl}/${projectToken}/${environment}/{{lng}}`;
// const loadPathWithNamespaces = `${cdnBaseUrl}/${projectToken}/${environment}/{{lng}}/{{ns}}`;
const endpoint = `https://api.simplelocalize.io/api/v1/translations?importOptions=REPLACE_TRANSLATION_IF_FOUND`;
const missingKeysPushInterval = 10_000; // 10 seconds

let missingKeysRequests: any[] = [];

const missingKeyHandler = (
  languages: readonly string[],
  namespace: string,
  key: string,
  fallbackValue: string,
) => {
  missingKeysRequests.push({
    key,
    //namespace: namespace, // uncomment if you use namespaces
    language: fallbackLanguage,
    text: fallbackValue,
  });
};

const pushMissingKeys = () => {
  // DO NOT PUSH MISSING KEYS IN PRODUCTION CODE
  const isProductionCode = process.env.NODE_ENV === "production";
  if (isProductionCode) {
    return;
  }

  if (missingKeysRequests.length > 0) {
    console.log(
      `[SimpleLocalize] Pushing missing keys: ${missingKeysRequests.length}`,
    );
    const requestBody = {
      content: missingKeysRequests,
    };
    fetch(endpoint, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "X-SimpleLocalize-Token": apiKey!,
      },
      body: JSON.stringify(requestBody),
    });
    missingKeysRequests = [];
  }
};

// @refresh reset
setInterval(() => pushMissingKeys(), missingKeysPushInterval);

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: loadPath, // comment if you use namespaces
      //loadPath: loadPathWithNamespaces # uncomment if you use namespaces
    },
    debug: process.env.NODE_ENV === "development",
    detection: {
      order: ["localStorage", "navigator"],
    },
    fallbackLng: fallbackLanguage,
    interpolation: { escapeValue: false },
    saveMissing: false, // here you can enable/disable missing keys feature in production code
    missingKeyHandler,
    supportedLngs: ['de', 'en', 'es', 'fr', 'it'],
  });

i18n.on("languageChanged", (lng) => {
  document.documentElement.setAttribute("lang", lng);
});

export default i18n;

import { createSlice } from '@reduxjs/toolkit';
import Modal from '../constants/interfaces/Modal';

const initialState: Modal = {
  isOpen: false,
};

export const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    eventsOpenChanged: (state, action) => {
      state.isOpen = action.payload;
    },
  },
});

export const { eventsOpenChanged } = eventsSlice.actions;

export default eventsSlice.reducer;

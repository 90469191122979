import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { camelCase } from "lodash";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function AdminIndex() {
  const { t } = useTranslation();
  const links = [
    {
      key: "emojis",
      icon: light("smile"),
    },
    {
      key: "boxes",
      icon: light("box"),
    },
    {
      key: "groups",
      icon: light("user-group"),
    },
    {
      key: "powers",
      icon: light("bolt"),
    },
    {
      key: "blitz-modifiers",
      icon: light("cloud-rainbow"),
    },
    {
      key: "recommendations",
      icon: light("lightbulb-exclamation"),
    },
    {
      key: "tags",
      icon: light("tag"),
    },
    {
      key: "worlds",
      icon: light("globe"),
    },
    {
      key: "other",
      icon: light("seal-question"),
    },
    {
      key: "events",
      icon: light("calendar"),
    },
    {
      key: "event-types",
      icon: light("stars"),
    },
    {
      key: "versions",
      icon: light("hashtag"),
    },
  ];

  return (
    <ul className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6">
      {links.map((link) => (
        <li className="text-slate-900 dark:text-slate-100" key={link.key}>
          <Link
            className="flex w-full flex-col items-center justify-center gap-2 rounded border border-slate-300 p-4 transition-colors hover:border-slate-400 hover:bg-slate-200 dark:border-slate-700 hover:dark:border-slate-600 hover:dark:bg-slate-800"
            to={`/admin/${link.key}`}
          >
            <FontAwesomeIcon
              className="h-7 w-7 text-purple-500 dark:text-purple-400"
              icon={link.icon}
            />
            <p>{t(`${camelCase(link.key)}.title`)}</p>
          </Link>
        </li>
      ))}
    </ul>
  );
}

export default AdminIndex;

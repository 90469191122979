import { createSlice } from "@reduxjs/toolkit";
import WhatsNew from "../constants/interfaces/WhatsNew";

const initialState: WhatsNew = {
  hasUpdate: false,
  isOpen: false,
};

export const whatsNewSlice = createSlice({
  name: "whatsNew",
  initialState,
  reducers: {
    hasUpdateChanged: (state, action) => {
      state.hasUpdate = action.payload;
    },
    whatsNewOpenChanged: (state, action) => {
      state.isOpen = action.payload;
    },
  },
});

export const { hasUpdateChanged, whatsNewOpenChanged } = whatsNewSlice.actions;

export default whatsNewSlice.reducer;

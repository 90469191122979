import { InputHTMLAttributes } from "react";
import { UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  name: string;
  onBlur?: any;
  onChange?: any;
  register?: UseFormRegister<any>;
}

const Input = ({
  label,
  name,
  onBlur,
  onChange,
  register,
  ...props
}: InputProps) => {
  const { t } = useTranslation();

  return (
    <div className={`${props.className} flex flex-col`}>
      {label && (
        <label
          className="text-slate-600 dark:text-slate-300"
          htmlFor={props.id}
        >
          {t(label)}{" "}
          {!props.disabled && !props.readOnly && (
            <>
              {props.required ? (
                <span className="text-red-500 dark:text-red-400">*</span>
              ) : (
                <span className="text-sm text-slate-400">
                  ({t("optional")})
                </span>
              )}
            </>
          )}
        </label>
      )}

      {register ? (
        <input
          {...props}
          {...register(name, { required: props.required, onBlur, onChange })}
          className={`${props.className} peer form-input w-full rounded border border-slate-300 bg-white text-sm text-slate-800 read-only:cursor-not-allowed read-only:opacity-50 focus:border-purple-500 focus:outline-none read-only:focus:border-slate-300 read-only:focus:ring-0 focus-visible:ring-2 focus-visible:ring-purple-500/50 disabled:!border-opacity-25 disabled:!bg-opacity-25 dark:border-slate-600 dark:bg-slate-800 dark:text-slate-100 dark:[color-scheme:dark] dark:focus:border-purple-400 read-only:dark:focus:border-slate-600 dark:focus-visible:ring-purple-400/50`}
        />
      ) : (
        <input
          {...props}
          className={`${props.className} peer form-input w-full rounded border border-slate-300 bg-white text-sm text-slate-800 read-only:cursor-not-allowed read-only:opacity-50 focus:border-purple-500 focus:outline-none read-only:focus:border-slate-300 read-only:focus:ring-0 focus-visible:ring-2 focus-visible:ring-purple-500/50 disabled:!border-opacity-25 disabled:!bg-opacity-25 dark:border-slate-600 dark:bg-slate-800 dark:text-slate-100 dark:[color-scheme:dark] dark:focus:border-purple-400 read-only:dark:focus:border-slate-600 dark:focus-visible:ring-purple-400/50`}
          onChange={onChange}
        />
      )}

      {props.children}
    </div>
  );
};

export default Input;

import { createSlice } from "@reduxjs/toolkit";
import Plausible from "plausible-tracker";
import { MultiValue } from "react-select";
import { Kind } from "../constants/enums/Kind";
import { Filter } from "../constants/types/Filter";

let searchParams: URLSearchParams = new URLSearchParams(window.location.search);

const { trackEvent } = Plausible();
const initialState: Array<Filter> = [
  // Tags
  ...(searchParams.has("tags")
    ? (searchParams.get("tags")?.split(",") as string[])?.map(
        (tag) => ({ kind: Kind.Tags, value: tag }) as Filter,
      )
    : []),
  // Worlds
  ...(searchParams.has("worlds")
    ? (searchParams.get("worlds")?.split(",") as string[])?.map(
        (world) => ({ kind: Kind.Worlds, value: world }) as Filter,
      )
    : []),
  // Powers
  ...(searchParams.has("powers")
    ? (searchParams.get("powers")?.split(",") as string[])?.map(
        (power) => ({ kind: Kind.Powers, value: power }) as Filter,
      )
    : []),
  // Blitz Modifiers
  ...(searchParams.has("blitzModifiers")
    ? (searchParams.get("blitzModifiers")?.split(",") as string[])?.map(
        (blitzModifier) =>
          ({ kind: Kind.BlitzModifiers, value: blitzModifier }) as Filter,
      )
    : []),
  // Recommendations
  ...(searchParams.has("recommendations")
    ? (searchParams.get("recommendations")?.split(",") as string[])?.map(
        (recommendation) =>
          ({ kind: Kind.Recommendations, value: recommendation }) as Filter,
      )
    : []),
  // Groups
  ...(searchParams.has("groups")
    ? (searchParams.get("groups")?.split(",") as string[])?.map(
        (group) => ({ kind: Kind.Groups, value: group }) as Filter,
      )
    : []),
  // Boxes
  ...(searchParams.has("boxes")
    ? (searchParams.get("boxes")?.split(",") as string[])?.map(
        (box) => ({ kind: Kind.Boxes, value: box }) as Filter,
      )
    : []),
  // Other
  ...(searchParams.has("other")
    ? (searchParams.get("other")?.split(",") as string[])?.map(
        (other) => ({ kind: Kind.Other, value: other }) as Filter,
      )
    : []),
  // Levels
  ...(searchParams.has("levels")
    ? (searchParams.get("levels")?.split(",") as string[])?.map(
        (level) => ({ kind: Kind.Levels, value: level }) as Filter,
      )
    : []),
  // Emojis
  ...(searchParams.has("emojis")
    ? (searchParams.get("emojis")?.split(",") as string[])?.map(
        (emoji) => ({ kind: Kind.Emojis, value: emoji }) as Filter,
      )
    : []),
];

const historyPushState = () => {
  const newRelativePathQuery =
    window.location.pathname +
    (Array.from(searchParams).length ? "?" + searchParams.toString() : "");

  window.history.pushState(null, "", newRelativePathQuery);
};

export const FiltersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    filtersChanged: (
      _,
      action: {
        payload: MultiValue<{ label: string; value: Filter }>;
        type: string;
      },
    ) => {
      searchParams = new URLSearchParams(window.location.search);

      const payload = action.payload.map((option) => option.value);

      // Tags
      const tags = action.payload
        .filter((option) => option.value.kind === Kind.Tags)
        .map((option) => option.value.value);

      if (tags.length) {
        searchParams.set("tags", tags.toString());
        trackEvent("Tags", {
          props: { selection: tags.toString() },
        });
      } else {
        searchParams.delete("tags");
      }

      // Worlds
      const worlds = action.payload
        .filter((option) => option.value.kind === Kind.Worlds)
        .map((option) => option.value.value);

      if (worlds.length) {
        searchParams.set("worlds", worlds.toString());
        trackEvent("Worlds", {
          props: { selection: worlds.toString() },
        });
      } else {
        searchParams.delete("worlds");
      }

      // Powers
      const powers = action.payload
        .filter((option) => option.value.kind === Kind.Powers)
        .map((option) => option.value.value);

      if (powers.length) {
        searchParams.set("powers", powers.toString());
        trackEvent("Powers", {
          props: { selection: powers.toString() },
        });
      } else {
        searchParams.delete("powers");
      }

      // Blitz Modifiers
      const blitzModifiers = action.payload
        .filter((option) => option.value.kind === Kind.BlitzModifiers)
        .map((option) => option.value.value);

      if (blitzModifiers.length) {
        searchParams.set("blitzModifiers", blitzModifiers.toString());
        trackEvent("Blitz Modifiers", {
          props: { selection: blitzModifiers.toString() },
        });
      } else {
        searchParams.delete("blitzModifiers");
      }

      // Recommendations
      const recommendations = action.payload
        .filter((option) => option.value.kind === Kind.Recommendations)
        .map((option) => option.value.value);

      if (recommendations.length) {
        searchParams.set("recommendations", recommendations.toString());
        trackEvent("Recommendations", {
          props: { selection: recommendations.toString() },
        });
      } else {
        searchParams.delete("recommendations");
      }

      // Groups
      const groups = action.payload
        .filter((option) => option.value.kind === Kind.Groups)
        .map((option) => option.value.value);

      if (groups.length) {
        searchParams.set("groups", groups.toString());
        trackEvent("Groups", {
          props: { selection: groups.toString() },
        });
      } else {
        searchParams.delete("groups");
      }

      // Boxes
      const boxes = action.payload
        .filter((option) => option.value.kind === Kind.Boxes)
        .map((option) => option.value.value);

      if (boxes.length) {
        searchParams.set("boxes", boxes.toString());
        trackEvent("Boxes", {
          props: { selection: boxes.toString() },
        });
      } else {
        searchParams.delete("boxes");
      }
      
      // Other
      const other = action.payload
        .filter((option) => option.value.kind === Kind.Other)
        .map((option) => option.value.value);

      if (other.length) {
        searchParams.set("other", other.toString());
        trackEvent("other", {
          props: { selection: other.toString() },
        });
      } else {
        searchParams.delete("other");
      }
      
      // Levels
      const levels = action.payload
        .filter((option) => option.value.kind === Kind.Levels)
        .map((option) => option.value.value);

      if (levels.length) {
        searchParams.set("levels", levels.toString());
        trackEvent("Levels", {
          props: { selection: levels.toString() },
        });
      } else {
        searchParams.delete("levels");
      }

      // Emojis
      const emojis = action.payload
        .filter((option) => option.value.kind === Kind.Emojis)
        .map((option) => option.value.value);

      if (emojis.length) {
        searchParams.set("emojis", emojis.toString());
        trackEvent("Emojis", {
          props: { selection: emojis.toString() },
        });
      } else {
        searchParams.delete("emojis");
      }


      historyPushState();

      return payload;
    },
    filtersCleared: () => {
      searchParams = new URLSearchParams(window.location.search);
      searchParams.delete("tags");
      searchParams.delete("worlds");
      searchParams.delete("powers");
      searchParams.delete("blitzModifiers");
      searchParams.delete("recommendations");
      searchParams.delete("groups");
      searchParams.delete("boxes");
      searchParams.delete("levels");
      searchParams.delete("levels");
      searchParams.delete("emojis");
      searchParams.delete("q");
      historyPushState();

      return [];
    },
    filterToggled: (state, action: { payload: Filter; type: string }) => {
      searchParams = new URLSearchParams(window.location.search);

      const index = state.findIndex(
        (filter) =>
          filter.kind === action.payload.kind &&
          filter.value === action.payload.value,
      );

      if (index !== -1) {
        // Remove the filter from the array
        state.splice(index, 1);
      } else {
        // Push the filter to the array
        state.push(action.payload);
      }

      const filteredState = state.filter(
        (filter) => filter.kind === action.payload.kind,
      );

      if (filteredState.length) {
        searchParams.set(
          action.payload.kind,
          filteredState.map((filter) => filter.value).toString(),
        );
      } else {
        searchParams.delete(action.payload.kind);
      }

      trackEvent(
        `${action.payload.kind[0].toUpperCase()}${action.payload.kind.slice(
          1,
        )}`,
        {
          props: {
            selection: filteredState.map((filter) => filter.value).toString(),
          },
        },
      );

      historyPushState();
    },
    filtersParamsChanged: () => {
      const payload: Filter[] = [];

      new URLSearchParams(window.location.search).forEach(
        (values: string, kind: string) => {
          if (kind !== "sort") {
            values.split(",").forEach((value) => {
              payload.push({
                kind: kind as Kind,
                value,
              });
            });
          }
        },
      );

      return payload;
    },
    filteredByEmojis: (
      _,
      action: {
        payload: string[];
        type: string;
      },
    ) => {
      searchParams = new URLSearchParams(window.location.search);

      searchParams.delete("tags");
      searchParams.delete("worlds");
      searchParams.delete("powers");
      searchParams.delete("blitzModifiers");
      searchParams.delete("recommendations");
      searchParams.delete("groups");
      searchParams.delete("boxes");
      searchParams.delete("other");
      searchParams.delete("levels");
      searchParams.delete("emojis");
      searchParams.delete("q");

      searchParams.set("emojis", action.payload.toString());
      trackEvent("Emojis", {
        props: { selection: action.payload.toString() },
      });

      historyPushState();

      return action.payload.map((emoji) => ({
        kind: Kind.Emojis,
        value: emoji,
      }));
    },
  },
});

export const {
  filtersChanged,
  filtersCleared,
  filterToggled,
  filtersParamsChanged,
  filteredByEmojis,
} = FiltersSlice.actions;

export default FiltersSlice.reducer;

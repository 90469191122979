import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import useSWR, { KeyedMutator } from "swr";
import Button from "../../../components/Button/Button";
import EmojiSelect from "../../../components/EmojiSelect/EmojiSelect";
import Input from "../../../components/Input/Input";
import SingleSelect from "../../../components/SingleSelect/SingleSelect";
import Document from "../../../constants/interfaces/Document";

const AdminEvent = () => {
  const [isVillain, setIsVillain] = useState(false);
  const { getToken } = useKindeAuth();
  const { id } = useParams();
  const { control, handleSubmit, register, setValue, watch } = useForm(
    id
      ? {
          defaultValues: async () => {
            return await axios
              .get(`${process.env.REACT_APP_API_ENDPOINT}/events/${id}`)
              .then((res) => {
                res.data.emojis = res.data.emojis?.map((emoji: string) => ({
                  label: t(`emojis.${emoji}`),
                  value: emoji,
                }));

                if (res.data.villain) {
                  if (Array.isArray(res.data.villain)) {
                    res.data.villain = res.data.villain?.map(
                      (villain: string) => ({
                        label: t(`emojis.${villain}`),
                        value: villain,
                      }),
                    );
                  } else {
                    res.data.villain = {
                      label: t(`emojis.${res.data.villain}`),
                      value: res.data.villain,
                    };
                  }

                  setIsVillain(true);
                }

                return res.data;
              });
          },
        }
      : {},
  );
  const { t } = useTranslation();
  const singleSelectKeys = ["type"];
  const navigate = useNavigate();

  const fetcher = (url: string) => axios.get(url).then((res) => res.data);

  // Event Types
  const { data: type }: { data: Array<Document>; mutate: KeyedMutator<any> } =
    useSWR(`${process.env.REACT_APP_API_ENDPOINT}/event-types`, fetcher);

  const onSubmit = async (data: any) => {
    const accessToken = await getToken();
    singleSelectKeys.forEach((key) => (data[key] = data[key]?.value));

    data.villain = data.villain?.map(
      (object: { label: string; value: string }) => object.value,
    );

    data.emojis = data.emojis?.map(
      (object: { label: string; value: string }) => object.value,
    );

    if (id) {
      const { _id, ...rest } = data;

      axios
        .put(`${process.env.REACT_APP_API_ENDPOINT}/events/${id}`, rest, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(() => navigate("/admin/events"));
    } else {
      axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}/events`, data, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(() => navigate(`/admin/events#${data._id}`));
    }
  };

  return (
    <>
      <div className="mb-4 flex gap-2">
        <FontAwesomeIcon
          className="h-7 w-7 text-purple-500 dark:text-purple-400"
          icon={light("calendar")}
        />

        <h1 className="text-xl font-bold text-slate-900 dark:text-slate-100">
          {id ? t("edit") : t("add")} {t("events.event")}
        </h1>
      </div>

      <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        {/* Type */}
        <SingleSelect
          control={control}
          inputId="type"
          label="eventTypes.eventType"
          name="type"
          onChange={(event: { label: string; value: string }) => {
            if (event.value === "villain") {
              setValue("name", null);
              setIsVillain(true);
            } else {
              setValue("villain", null);
              setIsVillain(false);
            }
          }}
          options={type?.map((type) => ({
            label: t(`eventTypes.${type._id}`),
            value: type._id,
          }))}
          required
        />

        {isVillain ? (
          <>
            {/* Villain */}
            <EmojiSelect
              control={control}
              inputId="villain"
              isMulti
              label="eventTypes.villain"
              name="villain"
              required
            />
          </>
        ) : (
          <>
            {/* Name */}
            <Input
              id="name"
              label="admin.name"
              name="name"
              register={register}
              required
            />
          </>
        )}

        {/* Start Date */}
        <Input
          id="startDate"
          label="events.startDate"
          name="startDate"
          register={register}
          required
          type="date"
        ></Input>

        {/* End Date */}
        <Input
          id="endDate"
          label="events.endDate"
          min={watch("startDate")}
          name="endDate"
          register={register}
          required
          type="date"
        ></Input>

        {/* Emojis */}
        <EmojiSelect
          control={control}
          inputId="emojis"
          isMulti
          label="emojis.title"
          name="emojis"
          required
        />

        <Button
          className="flex w-full gap-2"
          label={`${t("add")} ${t("events.event")}`}
          type="submit"
        >
          <FontAwesomeIcon className="h-5 w-5" icon={light("cloud-arrow-up")} />
          {t("save")} {t("events.event")}
        </Button>
      </form>
    </>
  );
};

export default AdminEvent;

import { configureStore } from '@reduxjs/toolkit';
import emojisReducer from './EmojisSlice';
import eventsReducer from './EventsSlice';
import feedbackReducer from './FeedbackSlice';
import filtersReducer from './FiltersSlice';
import loadingReducer from './LoadingSlice';
import recommendationsReducer from './RecommendationsSlice';
import settingsReducer from './SettingsSlice';
import whatsNewReducer from './WhatsNewSlice';

export const store = configureStore({
  reducer: {
    emojis: emojisReducer,
    events: eventsReducer,
    feedback: feedbackReducer,
    filters: filtersReducer,
    loading: loadingReducer,
    recommendations: recommendationsReducer,
    settings: settingsReducer,
    whatsNew: whatsNewReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

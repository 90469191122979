import { light, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Transition } from "@headlessui/react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { t } from "i18next";
import { Fragment } from "react";

type LevelProps = {
  arrayTo: number;
  className?: string;
  disabled: boolean;
  highlightCurrent?: boolean;
  level: number;
  onClick: any;
};

const Level = ({
  arrayTo,
  className,
  disabled,
  highlightCurrent = true,
  level,
  onClick,
}: LevelProps) => {
  const { isAuthenticated, login } = useKindeAuth();

  const levelIcon = (level: number) => {
    switch (level) {
      case 1:
        return solid("1");
      case 2:
        return solid("2");
      case 3:
        return solid("3");
      case 4:
        return solid("4");
      case 5:
        return solid("5");
      default:
        return solid("0");
    }
  };

  const handleOnClick = (key: number) => {
    onClick(key);
  };

  return isAuthenticated ? (
    <Menu as="div" className="relative flex">
      <Menu.Button
        className={`${className} fa-layers fa-fw fa-lg h-6 w-6 shrink-0 disabled:cursor-not-allowed disabled:opacity-50`}
        disabled={disabled}
        name={t("levels")}
      >
        <FontAwesomeIcon
          className="h-6 w-6 text-yellow-500"
          icon={level ? solid("shield") : light("shield")}
        />
        <FontAwesomeIcon
          className={`h-6 w-6 ${level ? "text-yellow-900" : "text-yellow-500"}`}
          icon={levelIcon(level)}
          transform="shrink-8 up-0.5"
        />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Menu.Items className="absolute right-0 z-10 mt-9 w-16 origin-top-right overflow-hidden rounded border border-slate-100 bg-white shadow-xl focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500/50 dark:border-slate-600 dark:bg-slate-700 dark:focus-visible:ring-purple-400/50">
          <div className="px-2 py-1 text-xs uppercase text-slate-400">
            {t("level")}
          </div>
          {Array.from(Array(arrayTo).keys()).map((key) => {
            const current =
              highlightCurrent && (level ? level === key : key === 0);

            return (
              <Menu.Item disabled={current} key={key}>
                {({ active }) => (
                  <button
                    className={`${
                      active
                        ? "bg-purple-500 text-white dark:bg-purple-400"
                        : "text-slate-800 dark:text-slate-100"
                    } ${
                      current ? "!text-opacity-50" : ""
                    } group flex w-full items-center p-2 text-sm`}
                    name={`${t("level")} ${key}`}
                    onClick={() => handleOnClick(key)}
                    type="button"
                  >
                    {key}
                    {current && (
                      <FontAwesomeIcon
                        className="ml-auto text-purple-500 dark:text-purple-400"
                        icon={light("check")}
                        fixedWidth
                      />
                    )}
                  </button>
                )}
              </Menu.Item>
            );
          })}
        </Menu.Items>
      </Transition>
    </Menu>
  ) : (
    <button name={t("levels")} onClick={login as any} type="button">
      <span
        className={`${className} fa-layers fa-fw fa-lg flex h-6 w-6 shrink-0`}
      >
        <FontAwesomeIcon
          className="h-6 w-6 text-yellow-500"
          icon={light("shield")}
        />
        <FontAwesomeIcon
          className="h-6 w-6 text-yellow-500"
          icon={levelIcon(0)}
          transform="shrink-8"
        />
      </span>
    </button>
  );
};

export default Level;

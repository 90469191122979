import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type AvailableFromProps = {
  className?: string;
  date?: string;
  showPastDates?: boolean;
};

const AvailableFrom = ({
  className,
  date,
  showPastDates,
}: AvailableFromProps) => {
  return (
    <>
      {date && (new Date(date) > new Date() || showPastDates) && (
        <div
          className={`${className ?? ''} ${
            new Date(date) > new Date()
              ? `bg-purple-500 text-white dark:bg-purple-400 dark:text-slate-900`
              : `border border-purple-500 text-purple-500 dark:border-purple-400 dark:text-purple-400`
          } mt-0.5 flex items-center gap-1 rounded-full py-0.5 px-2 text-xs`}
        >
          <FontAwesomeIcon
            className="h-3 w-3"
            icon={new Date(date) > new Date() ? light('lock') : light('unlock')}
          />
          <span>
            {new Date(date).toLocaleDateString(undefined, { timeZone: 'UTC' })}
          </span>
        </div>
      )}
    </>
  );
};

export default AvailableFrom;

import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";

const AdminVersion = () => {
  const { getToken } = useKindeAuth();
  const { id } = useParams();
  const { handleSubmit, register } = useForm(
    id
      ? {
          defaultValues: async () => {
            return await axios
              .get(`${process.env.REACT_APP_API_ENDPOINT}/versions/${id}`)
              .then((res) => res.data);
          },
        }
      : {},
  );
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSubmit = async (data: any) => {
    const accessToken = await getToken();

    if (id) {
      const { _id, ...rest } = data;

      axios
        .put(`${process.env.REACT_APP_API_ENDPOINT}/versions/${id}`, rest, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(() => navigate("/admin/versions"));
    } else {
      axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}/versions`, data, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(() => navigate(`/admin/versions#${data._id}`));
    }
  };

  return (
    <>
      <div className="mb-4 flex gap-2">
        <FontAwesomeIcon
          className="h-7 w-7 text-purple-500 dark:text-purple-400"
          icon={light("hashtag")}
        />

        <h1 className="text-xl font-bold text-slate-900 dark:text-slate-100">
          {id ? t("edit") : t("add")} {t("versions.version")}
        </h1>
      </div>

      <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        {/* Version */}
        <Input
          id="version"
          label="versions.version"
          name="version"
          register={register}
          required
          type="number"
        ></Input>

        {/* Date */}
        <Input
          id="date"
          label="versions.date"
          name="date"
          register={register}
          required
          type="date"
        ></Input>

        <Button
          className="flex w-full gap-2"
          label={`${t("add")} ${t("versions.version")}`}
          type="submit"
        >
          <FontAwesomeIcon className="h-5 w-5" icon={light("cloud-arrow-up")} />
          {t("save")} {t("versions.version")}
        </Button>
      </form>
    </>
  );
};

export default AdminVersion;

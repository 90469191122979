import { ButtonHTMLAttributes } from "react";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  color?: string;
  secondary?: boolean;
  label: string;
}

const Button = ({
  className,
  color = "purple",
  secondary,
  label,
  ...props
}: ButtonProps) => {
  return (
    <button
      {...props}
      aria-label={label}
      className={`${className ?? ""} ${
        secondary
          ? `bg-slate-500 hover:bg-slate-600 focus-visible:ring-slate-500/50 disabled:hover:bg-slate-500`
          : `bg-${color}-500 hover:bg-${color}-600 focus-visible:ring-${color}-500/50 disabled:hover:bg-${color}-500 dark:bg-${color}-600 dark:hover:bg-${color}-700 dark:focus-visible:ring-${color}-600/50 disabled:dark:hover:bg-${color}-600`
      } inline-flex min-w-[38px] items-center justify-center gap-1 rounded border border-transparent p-2 text-sm font-bold text-white transition-colors focus:outline-none focus-visible:ring-2 disabled:cursor-not-allowed disabled:opacity-50 dark:text-slate-100`}
      type={props.type ?? "button"}
    >
      {props.children}
    </button>
  );
};

export default Button;

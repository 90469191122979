import { light, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Menu,
  Popover,
  RadioGroup,
  Switch,
  Transition,
} from "@headlessui/react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import axios from "axios";
import {
  Fragment,
  Suspense,
  lazy,
  memo,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { install } from "resize-observer";
import useSWR, { KeyedMutator } from "swr";
import { Layout } from "../../constants/enums/Layout";
import { Sort } from "../../constants/enums/Sort";
import { Theme } from "../../constants/enums/Theme";
import { Toggle } from "../../constants/enums/Toggle";
import Version from "../../constants/interfaces/Version";
import { eventsOpenChanged } from "../../store/EventsSlice";
import { filtersCleared } from "../../store/FiltersSlice";
import { useAppDispatch, useAppSelector } from "../../store/Hooks";
import { loadingChanged } from "../../store/LoadingSlice";
import {
  headerHeightChanged,
  layoutChanged,
  settingsChanged,
  sortChanged,
  themeChanged,
} from "../../store/SettingsSlice";
import { whatsNewOpenChanged } from "../../store/WhatsNewSlice";
import Button from "../Button/Button";
import Loading from "../Loading/Loading";
import Search from "../Search/Search";

const Header = () => {
  const { logout, login, isAuthenticated } = useKindeAuth();
  const { i18n, t } = useTranslation();
  const [hasUpdate, setHasUpdate] = useState(false);
  const [showDonateBanner, setShowDonateBanner] = useState(false);
  const [languages, setLanguages] = useState<{ key: string; name: string }[]>();
  const count = useAppSelector((state) => state.emojis.count);
  const currentYear = new Date().getFullYear();
  const dispatch = useAppDispatch();
  const eventsIsOpen = useAppSelector((state) => state.events.isOpen);
  const Events = lazy(() => import("../Events/Events"));
  const foundingYear = 2021;
  const headerHeight = useAppSelector((state) => state.settings.headerHeight);
  const isLoading = useAppSelector((state) => state.loading.isLoading);
  const layoutMethod = useAppSelector((state) => state.settings.layout);
  const headerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const settings = useAppSelector((state) => state.settings);
  const showAnimatePulse = isLoading ? "animate-pulse" : "";
  const sortIcon = (sort: Sort) => {
    switch (sort) {
      case Sort.Date:
        return light("calendar-arrow-down");
      case Sort.Groups:
        return light("arrow-down-big-small");
      case Sort.Name:
        return light("arrow-down-a-z");
      case Sort.Position:
        return light("arrow-down-1-9");
      case Sort.Trigger:
        return light("arrow-down-short-wide");
    }
  };
  const sortMethod = useAppSelector((state) => state.settings.sort);
  const themeIcon = (theme: Theme) => {
    switch (theme) {
      case Theme.Auto:
        return light("wand-magic-sparkles");
      case Theme.Dark:
        return light("moon");
      case Theme.Light:
        return light("sun");
    }
  };
  const toggleIcon = (toggle: Toggle) => {
    switch (toggle) {
      case Toggle.Tags:
        return light("tag");
      case Toggle.Worlds:
        return light("globe");
      case Toggle.Powers:
        return light("bolt");
      case Toggle.BlitzModifiers:
        return light("cloud-rainbow");
      case Toggle.Recommendations:
        return light("lightbulb-exclamation");
      case Toggle.Groups:
        return light("user-group");
      case Toggle.Triggers:
        return light("bullseye-arrow");
    }
  };
  const fetcher = (url: string) => axios.get(url).then((res) => res.data);
  const { data: version }: { data: Version; mutate: KeyedMutator<any> } =
    useSWR(`${process.env.REACT_APP_API_ENDPOINT}/versions/latest`, fetcher);
  const whatsNewIsOpen = useAppSelector((state) => state.whatsNew.isOpen);
  const WhatsNew = lazy(() => import("../WhatsNew/WhatsNew"));

  const hideDonateBanner = () => {
    setShowDonateBanner(false);
    localStorage.setItem("donateBannerSeen", new Date().toISOString());
  };

  useEffect(() => {
    const lastUpdateSeen: Date | null = localStorage.getItem("lastUpdateSeen")
      ? new Date(localStorage.getItem("lastUpdateSeen") as string)
      : null;
    const donateBannerSeen: Date | null = localStorage.getItem(
      "donateBannerSeen",
    )
      ? new Date(localStorage.getItem("donateBannerSeen") as string)
      : null;

    if (!lastUpdateSeen || lastUpdateSeen < new Date(version?.date)) {
      setHasUpdate(true);
    }

    if (!donateBannerSeen || donateBannerSeen < new Date(version?.date)) {
      setShowDonateBanner(true);
    }

    axios
      .get("https://api.simplelocalize.io/api/v1/languages", {
        headers: {
          "Content-Type": "application/json",
          "X-SimpleLocalize-Token":
            process.env.REACT_APP_SIMPLELOCALIZE_API_KEY,
        },
      })
      .then((res) => {
        setLanguages(res.data.data);
      });
  }, [version?.date]);

  useEffect(() => {
    const current = headerRef.current;

    if (current) {
      if (!window.ResizeObserver) install();

      new ResizeObserver((entries) => {
        window.requestAnimationFrame(() => {
          entries.forEach((entry) => {
            const height = entry.borderBoxSize?.[0]?.blockSize;

            if (headerHeight && height !== headerHeight) {
              dispatch(headerHeightChanged(current.offsetHeight));
            }
          });
        });
      }).observe(current, { box: "border-box" });
    }
  }, [dispatch, headerHeight]);

  return (
    <header
      className="fixed left-0 top-0 z-20 w-full shadow-sm transition-all"
      ref={headerRef}
    >
      {showDonateBanner && (
        <div className="flex flex-col gap-2 bg-yellow-500 p-2 text-sm text-yellow-950 md:flex-row md:items-center md:justify-between md:gap-4">
          <p>{t("donateBanner.text")}</p>
          <div className="flex justify-between gap-4">
            <button
              className="text-nowrap underline decoration-yellow-700 decoration-2"
              onClick={hideDonateBanner}
            >
              {t("donateBanner.hide")}
            </button>
            <a
              className="text-nowrap rounded border border-transparent bg-yellow-900 p-2 text-sm font-bold text-white transition-colors hover:bg-yellow-950 focus:outline-none focus-visible:ring-2 focus-visible:ring-yellow-900/50"
              href="https://www.buymeacoffee.com/icejd"
              onClick={hideDonateBanner}
              rel="noreferrer"
              target="_blank"
            >
              {t("donateBanner.donate")}
            </a>
          </div>
        </div>
      )}

      <div className="relative z-10 border-b border-purple-400 bg-purple-500 p-1 shadow-sm dark:border-purple-500 dark:bg-purple-600">
        <div className="flex justify-between gap-0.5 pb-1">
          {/* Logo */}
          <div className="grow">
            <button
              aria-label="DEB Wizard"
              className="flex items-center gap-2"
              name="DEB Wizard"
              onClick={() => {
                navigate("/");
                dispatch(loadingChanged(true));
                dispatch(filtersCleared());
              }}
              type="button"
            >
              <div className="flex h-9 w-9 items-center justify-center rounded shadow-sm bg-gradient-crystal-to-tl">
                <FontAwesomeIcon
                  className="h-6 w-6 text-white"
                  icon={solid("wand-sparkles")}
                />
              </div>
              <h1 className="text-xl font-bold text-white dark:text-slate-100">
                DEB Wizard
              </h1>
            </button>
          </div>

          {/* Sign In/Out Button */}
          <div className="inline-flex">
            <Button
              className="border-0 !bg-white py-1 !text-purple-500 hover:!bg-purple-100 focus-visible:!ring-white/50 disabled:hover:bg-white dark:!bg-slate-100 dark:!text-purple-600 hover:dark:!bg-purple-200 dark:focus-visible:!ring-slate-100/50 disabled:dark:hover:!bg-slate-100"
              label={isAuthenticated ? t("signOut") : t("signIn")}
              onClick={isAuthenticated ? (logout as any) : (login as any)}
            >
              <FontAwesomeIcon
                className="h-6 w-6"
                icon={
                  isAuthenticated ? solid("circle-user") : light("circle-user")
                }
              />
              {isAuthenticated ? t("signOut") : t("signIn")}
            </Button>
          </div>

          {/* What's New Button */}
          <div className="relative inline-flex">
            <button
              aria-label="What's New?"
              className="flex h-9 w-9 shrink-0 items-center justify-center rounded hover:bg-purple-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/50 dark:hover:bg-purple-800 dark:focus-visible:ring-slate-100/50"
              onClick={() => dispatch(whatsNewOpenChanged(true))}
              type="button"
            >
              <FontAwesomeIcon
                className="h-6 w-6 text-white dark:text-slate-100"
                icon={light("bell")}
              />
            </button>
            {hasUpdate && (
              <span className="absolute right-0 top-0 flex h-3 w-3">
                <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-red-500 opacity-75"></span>
                <span className="relative inline-flex h-3 w-3 rounded-full bg-red-500"></span>
              </span>
            )}
          </div>

          {/* What's New Modal */}
          {whatsNewIsOpen && (
            <Suspense fallback={<Loading />}>
              <WhatsNew />
            </Suspense>
          )}

          {/* Events Button */}
          <div className="inline-flex">
            <button
              aria-label="Events"
              className="flex h-9 w-9 shrink-0 items-center justify-center rounded hover:bg-purple-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/50 dark:hover:bg-purple-800 dark:focus-visible:ring-slate-100/50"
              onClick={() => dispatch(eventsOpenChanged(true))}
              type="button"
            >
              <FontAwesomeIcon
                className="h-6 w-6 text-white dark:text-slate-100"
                icon={light("calendar")}
              />
            </button>
          </div>

          {/* Events Modal */}
          {eventsIsOpen && (
            <Suspense fallback={<Loading />}>
              <Events />
            </Suspense>
          )}

          {/* Menu */}
          <Popover className="relative">
            {({ open }) => (
              <>
                <Popover.Button
                  aria-label="Menu"
                  className={`${
                    open ? "bg-purple-700 dark:bg-purple-800" : ""
                  } flex h-9 w-9 shrink-0 items-center justify-center rounded hover:bg-purple-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/50 dark:hover:bg-purple-800 dark:focus-visible:ring-slate-100/50`}
                >
                  <FontAwesomeIcon
                    className="h-6 w-6 text-white dark:text-slate-100"
                    icon={light("bars")}
                  />
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute right-0 z-10 mt-1 w-screen max-w-md pl-2">
                    <div className="menu overflow-auto rounded shadow-xl ring-1 ring-slate-200 dark:ring-slate-500">
                      <div className="flex flex-col bg-white text-slate-900 dark:bg-slate-700 dark:text-slate-100">
                        <div className="flex flex-col gap-2 border-b border-slate-100 p-2 dark:border-slate-500">
                          {/* Language */}
                          <p className="font-bold">{t("language.title")}</p>

                          <Menu as="div" className="flex items-center gap-2">
                            <FontAwesomeIcon
                              className="h-6 w-6 text-purple-500 dark:text-purple-400"
                              icon={light("language")}
                            />
                            <div className="relative grow">
                              <Menu.Button className="inline-flex w-full items-center justify-between rounded border border-slate-200 bg-slate-100 px-2 py-1 text-slate-800 focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500/50 dark:border-slate-500 dark:bg-slate-600 dark:text-slate-100 dark:focus-visible:ring-purple-400/50">
                                <>
                                  {
                                    languages?.find(
                                      (language) =>
                                        language.key === i18n.language,
                                    )?.name
                                  }
                                  <FontAwesomeIcon
                                    className="ml-2 w-3"
                                    icon={light("chevron-down")}
                                  />
                                </>
                              </Menu.Button>
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 translate-y-1"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-1"
                              >
                                <Menu.Items className="absolute right-0 z-10 mt-2 w-full origin-top-right overflow-hidden rounded border border-slate-200 bg-slate-100 shadow-xl focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500/50 dark:border-slate-500 dark:bg-slate-600 dark:focus-visible:ring-purple-400/50">
                                  <div>
                                    {languages?.map((language) => {
                                      const current =
                                        i18n.language === language.key;

                                      return (
                                        <Menu.Item
                                          disabled={current}
                                          key={language.key}
                                        >
                                          {({ active }) => (
                                            <button
                                              aria-label="Language"
                                              className={`${
                                                active
                                                  ? "bg-purple-500 text-white dark:bg-purple-400"
                                                  : "text-slate-800 dark:text-slate-100"
                                              } ${
                                                current
                                                  ? "!text-opacity-50"
                                                  : ""
                                              } group flex w-full items-center p-2`}
                                              onClick={() => {
                                                i18n.changeLanguage(
                                                  language.key,
                                                );
                                              }}
                                            >
                                              {language.name}
                                              {current && (
                                                <FontAwesomeIcon
                                                  className="ml-auto text-purple-500 dark:text-purple-400"
                                                  icon={light("check")}
                                                  fixedWidth
                                                />
                                              )}
                                            </button>
                                          )}
                                        </Menu.Item>
                                      );
                                    })}
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </div>
                          </Menu>

                          {/* Settings */}
                          <p className="font-bold">{t("settings.title")}</p>

                          {Object.values(Toggle).map((toggle) => (
                            <Switch.Group key={toggle}>
                              <div className="flex items-center justify-between">
                                <Switch.Label
                                  className={`${
                                    !settings[toggle]
                                      ? "text-slate-900/50 dark:text-slate-100/50"
                                      : ""
                                  } flex items-center gap-2`}
                                >
                                  <>
                                    <FontAwesomeIcon
                                      className={`${
                                        !settings[toggle]
                                          ? "text-purple-500/50 dark:text-purple-400/50"
                                          : ""
                                      } h-6 w-6 text-purple-500 dark:text-purple-400`}
                                      icon={toggleIcon(toggle)}
                                    />
                                    {t(`settings.${toggle}`)}
                                  </>
                                </Switch.Label>

                                <Switch
                                  checked={settings[toggle]}
                                  className={`${
                                    settings[toggle]
                                      ? "bg-purple-500 dark:bg-purple-400"
                                      : "bg-slate-300 dark:bg-slate-900"
                                  } relative inline-flex h-6 w-12 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500/50 dark:focus-visible:ring-slate-100/75`}
                                  onChange={() =>
                                    dispatch(
                                      settingsChanged({
                                        name: toggle,
                                        checked: !settings[toggle],
                                      }),
                                    )
                                  }
                                >
                                  <span
                                    aria-hidden="true"
                                    className={`${
                                      settings[toggle]
                                        ? "translate-x-6"
                                        : "translate-x-0"
                                    } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out dark:bg-slate-100`}
                                  />
                                </Switch>
                              </div>
                            </Switch.Group>
                          ))}
                        </div>

                        <div className="flex p-2">
                          <RadioGroup
                            value={settings.theme}
                            onChange={(event) => dispatch(themeChanged(event))}
                          >
                            <RadioGroup.Label className="mb-2 block font-bold">
                              {t("settings.theme.title")}
                            </RadioGroup.Label>
                            <div className="flex gap-2">
                              {Object.values(Theme).map((theme) => (
                                <RadioGroup.Option
                                  className={({ active, checked }) =>
                                    `${
                                      active
                                        ? "ring-2 ring-purple-500/50 dark:ring-purple-400/50"
                                        : ""
                                    } ${
                                      checked
                                        ? "border-purple-500 bg-purple-500/20 dark:border-purple-400 dark:bg-purple-400/20"
                                        : "border-slate-200 dark:border-slate-500"
                                    } relative flex cursor-pointer rounded border bg-slate-100 p-1 focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500/50 dark:bg-slate-600 dark:focus-visible:ring-purple-400/50`
                                  }
                                  key={theme}
                                  value={theme}
                                >
                                  {({ active, checked }) => (
                                    <RadioGroup.Label
                                      as="p"
                                      className={`${
                                        checked
                                          ? "text-purple-500 dark:text-purple-400"
                                          : "text-slate-800 dark:text-slate-100"
                                      } flex items-center gap-2`}
                                    >
                                      <FontAwesomeIcon
                                        className="h-6 w-6"
                                        icon={themeIcon(theme)}
                                      />
                                      <span>
                                        {t(`settings.theme.${theme}`)}
                                      </span>
                                    </RadioGroup.Label>
                                  )}
                                </RadioGroup.Option>
                              ))}
                            </div>
                          </RadioGroup>
                        </div>
                      </div>

                      <div className="flex flex-col gap-1 bg-slate-100 p-2 text-slate-500 dark:bg-slate-600 dark:text-slate-300">
                        {/* Acknowledgements */}
                        <p className="font-bold">
                          {t("footer.acknowledgements.title")}
                        </p>
                        <ul className="mb-2 space-y-1 text-sm">
                          <li className="flex items-center gap-2">
                            <FontAwesomeIcon
                              className="h-6 w-6 text-purple-500 dark:text-purple-400"
                              icon={light("hands-clapping")}
                            />
                            <span>
                              {t("footer.acknowledgements.info")}{" "}
                              <a
                                className="underline decoration-purple-500 decoration-2 hover:decoration-transparent focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500/50 dark:decoration-purple-400 dark:focus-visible:ring-purple-400/50"
                                href="https://www.reddit.com/u/IceJD"
                                rel="noreferrer"
                                target="_blank"
                              >
                                IceJD
                              </a>
                              .
                            </span>
                          </li>
                          <li className="flex items-center gap-2">
                            <FontAwesomeIcon
                              className="h-6 w-6 text-purple-500 dark:text-purple-400"
                              icon={light("hands-clapping")}
                            />
                            <span>
                              {t("footer.acknowledgements.recommendations")}{" "}
                              <a
                                className="underline decoration-purple-500 decoration-2 hover:decoration-transparent focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500/50 dark:decoration-purple-400 dark:focus-visible:ring-purple-400/50"
                                href="https://www.reddit.com/u/IceJD"
                                rel="noreferrer"
                                target="_blank"
                              >
                                IceJD
                              </a>
                              .
                            </span>
                          </li>
                          <li className="flex items-center gap-2">
                            <FontAwesomeIcon
                              className="h-6 w-6 text-purple-500 dark:text-purple-400"
                              icon={light("hands-clapping")}
                            />
                            <span>
                              {t("footer.acknowledgements.images")}{" "}
                              <a
                                className="underline decoration-purple-500 decoration-2 hover:decoration-transparent focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500/50 dark:decoration-purple-400 dark:focus-visible:ring-purple-400/50"
                                href="https://www.reddit.com/u/CubbyJad"
                                rel="noreferrer"
                                target="_blank"
                              >
                                CubbyJad
                              </a>
                              .
                            </span>
                          </li>
                          <li className="flex items-center gap-2">
                            <FontAwesomeIcon
                              className="h-6 w-6 text-purple-500 dark:text-purple-400"
                              icon={light("hands-clapping")}
                            />
                            <span>
                              {t("footer.acknowledgements.translations")}{" "}
                              <a
                                className="underline decoration-purple-500 decoration-2 hover:decoration-transparent focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500/50 dark:decoration-purple-400 dark:focus-visible:ring-purple-400/50"
                                href="https://www.reddit.com/u/IceJD"
                                rel="noreferrer"
                                target="_blank"
                              >
                                IceJD
                              </a>
                              ,{" "}
                              <a
                                className="underline decoration-purple-500 decoration-2 hover:decoration-transparent focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500/50 dark:decoration-purple-400 dark:focus-visible:ring-purple-400/50"
                                href="https://www.reddit.com/u/sarahjadesigns"
                                rel="noreferrer"
                                target="_blank"
                              >
                                sarahjadesigns
                              </a>{" "}
                              {t("and")}{" "}
                              <a
                                className="underline decoration-purple-500 decoration-2 hover:decoration-transparent focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500/50 dark:decoration-purple-400 dark:focus-visible:ring-purple-400/50"
                                href="https://www.reddit.com/u/silvia333"
                                rel="noreferrer"
                                target="_blank"
                              >
                                silvia333
                              </a>
                              .
                            </span>
                          </li>
                        </ul>

                        {/* Version information */}
                        <p
                          className="text-xs"
                          dangerouslySetInnerHTML={{
                            __html: t("footer.update", {
                              version: version?.version,
                              date: new Date(version?.date).toLocaleDateString(
                                undefined,
                                {
                                  timeZone: "UTC",
                                },
                              ),
                            }),
                          }}
                        ></p>

                        {/* Disclaimer */}
                        <p className="text-xs text-slate-400">
                          {t("footer.disclaimer")}
                        </p>

                        {/* Copyright */}
                        <p className="text-xs text-slate-400">
                          &copy;{" "}
                          {currentYear > foundingYear
                            ? `${foundingYear}–${currentYear}`
                            : foundingYear}{" "}
                          {t("footer.copyright")}
                        </p>
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
        </div>

        {/* Search */}
        <Search />
      </div>

      <nav className="flex items-center gap-2 border-b border-slate-100/50 bg-slate-100/50 p-1 text-sm text-slate-800 backdrop-blur backdrop-filter dark:border-slate-700 dark:bg-slate-800/50 dark:text-slate-100">
        <div className="flex-grow font-bold">
          {!isLoading ? (
            `${count} ${t("emojis.title", {
              count: count,
            })}`
          ) : (
            <div className={showAnimatePulse}>
              <div className="h-6 w-16 rounded bg-slate-100 dark:bg-slate-700"></div>
            </div>
          )}
        </div>

        <div className="flex items-center gap-1">
          <span>{t("sort.label")}</span>
          <Menu as="div" className="relative inline-block">
            <Menu.Button className="inline-flex w-full items-center justify-center rounded border border-slate-200 bg-white px-2 py-1 text-sm text-slate-800 focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500/50 dark:border-slate-600 dark:bg-slate-700 dark:text-slate-100 dark:focus-visible:ring-purple-400/50">
              <>
                {t(`sort.${sortMethod}`)}
                <FontAwesomeIcon
                  className="ml-2 w-3"
                  icon={light("chevron-down")}
                />
              </>
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Menu.Items className="absolute right-0 mt-2 origin-top-right overflow-hidden rounded border border-slate-100 bg-white shadow-xl focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500/50 dark:border-slate-600 dark:bg-slate-700 dark:focus-visible:ring-purple-400/50">
                <div>
                  {Object.values(Sort).map((sort) => {
                    const current = sortMethod === sort;

                    return (
                      <Menu.Item disabled={current} key={sort}>
                        {({ active }) => (
                          <button
                            aria-label={t(`sort.${sort}`)}
                            className={`${
                              active
                                ? "bg-purple-500 text-white dark:bg-purple-400"
                                : "text-slate-800 dark:text-slate-100"
                            } ${
                              current ? "!text-opacity-50" : ""
                            } group flex w-full items-center whitespace-nowrap p-2 text-sm`}
                            onClick={() => {
                              dispatch(loadingChanged(true));
                              dispatch(sortChanged(sort));
                            }}
                          >
                            <FontAwesomeIcon
                              className={`${
                                active
                                  ? "text-white dark:text-slate-100"
                                  : "text-purple-500 dark:text-purple-400"
                              } ${current ? "!text-opacity-50" : ""} mr-1 `}
                              icon={sortIcon(sort)}
                              fixedWidth
                            />
                            {t(`sort.${sort}`)}
                            {current && (
                              <FontAwesomeIcon
                                className="ml-auto text-purple-500 dark:text-purple-400"
                                icon={light("check")}
                                fixedWidth
                              />
                            )}
                          </button>
                        )}
                      </Menu.Item>
                    );
                  })}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>

        <div className="flex items-center gap-1">
          {Object.values(Layout).map((layout: Layout) => {
            const icon = layout === Layout.List ? light("list") : light("grid");

            return (
              <button
                aria-label={`View as ${layout}`}
                className={`${
                  layoutMethod === layout
                    ? "border-purple-500 bg-purple-500/20 text-purple-500 dark:border-purple-400 dark:bg-purple-400/20 dark:text-purple-400"
                    : "border-slate-200 bg-white text-slate-800 dark:border-slate-600 dark:bg-slate-700 dark:text-slate-100"
                } w-[30px] rounded border p-1 text-sm transition-colors focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500/50 dark:focus-visible:ring-purple-400/50`}
                key={layout}
                onClick={() => dispatch(layoutChanged(layout))}
                type="button"
              >
                <FontAwesomeIcon icon={icon} />
              </button>
            );
          })}
        </div>
      </nav>
    </header>
  );
};

export default memo(Header);

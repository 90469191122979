import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import axios from "axios";
import { t } from "i18next";
import { IKImage } from "imagekitio-react";
import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import {
  ClearIndicatorProps,
  DropdownIndicatorProps,
  MenuProps,
  MultiValue,
  MultiValueGenericProps,
  MultiValueRemoveProps,
  OptionProps,
  StylesConfig,
  WindowedMenuList,
  components,
} from "react-windowed-select";
import useSWR, { KeyedMutator } from "swr";
import tw from "twin.macro";
import { Kind } from "../../constants/enums/Kind";
import { Theme } from "../../constants/enums/Theme";
import Document from "../../constants/interfaces/Document";
import Emoji from "../../constants/interfaces/Emoji";
import { Filter } from "../../constants/types/Filter";
import { filtersChanged } from "../../store/FiltersSlice";
import { useAppDispatch, useAppSelector } from "../../store/Hooks";
import { loadingChanged } from "../../store/LoadingSlice";

const ClearIndicator = (
  props: ClearIndicatorProps<{ label: string; value: Filter }, true>,
) => (
  <components.ClearIndicator {...props}>
    <FontAwesomeIcon className="h-4 w-4" icon={light("xmark")} />
  </components.ClearIndicator>
);
const DropdownIndicator = (
  props: DropdownIndicatorProps<{ label: string; value: Filter }, true>,
) => (
  <components.DropdownIndicator {...props}>
    <FontAwesomeIcon className="h-4 w-4" icon={light("chevron-down")} />
  </components.DropdownIndicator>
);
const Menu = (props: MenuProps<{ label: string; value: Filter }, true>) => (
  <components.Menu {...props} className="menu"></components.Menu>
);
const MultiValueLabel = (
  props: MultiValueGenericProps<{ label: string; value: Filter }>,
) => (
  <components.MultiValueLabel {...props}>
    <div className="flex items-center gap-1">
      {props.data.value.kind === Kind.Emojis ? (
        <IKImage
          alt={props.data.label}
          height="16"
          lqip={{ active: true }}
          path={props.data.value.filePath}
          transformation={[
            {
              di: "error.png",
              dpr: window.devicePixelRatio.toString(),
              width: "16",
              height: "16",
              cropMode: "pad_resize",
            },
          ]}
          urlEndpoint={process.env.REACT_APP_IMAGEKIT_URL_ENDPOINT}
          width="16"
        />
      ) : (
        <FontAwesomeIcon
          className="h-4 w-4"
          icon={filterIcon(props.data.value)}
        />
      )}
      <span>
        {props.data.value.kind === Kind.Recommendations
          ? recommendationLabel(props.data, "16")
          : props.data.label}
      </span>
    </div>
  </components.MultiValueLabel>
);
const MultiValueRemove = (
  props: MultiValueRemoveProps<{ label: string; value: Filter }>,
) => {
  const innerProps = {
    ...props.innerProps,
    "aria-label": `Remove ${props.data.label}`,
  };

  return (
    <components.MultiValueRemove {...props} innerProps={innerProps}>
      <FontAwesomeIcon className="h-3" icon={light("xmark")} />
    </components.MultiValueRemove>
  );
};
const styles: StylesConfig<{ label: string; value: Filter }, true> = {
  clearIndicator: (provided) => ({
    ...provided,
    ...tw`p-[7px] text-slate-900 transition-colors hover:text-purple-500 dark:text-slate-100 dark:hover:text-purple-400`,
  }),
  control: (provided, state) => ({
    ...provided,
    ...(state.isFocused ? tw`ring-2 ring-white/50` : ``),
    ...tw`min-h-[30px] rounded border-slate-200 bg-white shadow-none hover:border-slate-200 dark:border-slate-700 dark:bg-slate-800 dark:hover:border-slate-700`,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    ...tw`p-[7px] text-slate-900 transition-colors hover:text-purple-500 dark:text-slate-100 dark:hover:text-purple-400`,
  }),
  groupHeading: (provided) => ({
    ...provided,
    height: 18,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    ...tw`bg-slate-400`,
  }),
  input: (provided) => ({
    ...provided,
    ...tw`text-slate-900 dark:text-slate-100 text-sm my-0`,
  }),
  menu: (provided) => ({
    ...provided,
    ...tw`mt-1 translate-y-1 rounded border border-slate-200 bg-white shadow-xl transition duration-200 dark:border-slate-600 dark:bg-slate-700`,
  }),
  multiValue: (provided) => ({
    ...provided,
    ...tw`m-0 h-6 gap-1 rounded-full bg-purple-500/20 text-purple-500 ring-2 ring-purple-500 dark:bg-purple-400/20 dark:text-purple-400 dark:ring-purple-400`,
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    ...tw`py-0.5 pl-1 pr-0 text-purple-500 dark:text-purple-400`,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    ...tw`rounded-none bg-transparent pl-0 pr-1.5 transition-colors hover:text-red-500 dark:hover:text-red-400`,
  }),
  option: (provided, state) => ({
    ...provided,
    ...(state.isFocused ? tw`bg-purple-500/20 dark:bg-purple-400/20` : ``),
    ...tw`hover:bg-purple-500/20 dark:hover:bg-purple-400/20`,
    height: 40,
  }),
  placeholder: (provided) => ({
    ...provided,
    ...tw`text-slate-400`,
  }),
  valueContainer: (provided) => ({
    ...provided,
    ...tw`gap-2 p-[5px]`,
  }),
};

const filterIcon = (filter: Filter) => {
  switch (filter.kind) {
    case Kind.Tags:
      return light("tag");
    case Kind.Worlds:
      return light("globe");
    case Kind.Powers:
      return light("bolt");
    case Kind.BlitzModifiers:
      return light("cloud-rainbow");
    case Kind.Recommendations:
      return light("lightbulb-exclamation");
    case Kind.Groups:
      return light("user-group");
    case Kind.Boxes:
      return light("box");
    case Kind.Other:
      return light("seal-question");
    case Kind.Levels:
      return light("shield");
    case Kind.Emojis:
      return light("face-smile");
  }
};

const recommendationLabel = (
  data: { label: string; value: Filter },
  size: string = "24",
) => {
  if (data.value.value.startsWith("combo")) {
    const images = data.value.value
      .slice(5)
      .split("Plus")
      .map((image) => image.charAt(0).toLocaleLowerCase() + image.slice(1));

    return (
      <span className="flex items-center">
        {t("recommendations.combo")}&nbsp;
        <IKImage
          alt={t(`recommendations.${images[0]}`)}
          className={size === "16" ? "h-4" : "h-6"}
          height={size}
          lqip={{ active: true }}
          path={`${images[0]}.png`}
          transformation={[
            {
              dpr: window.devicePixelRatio.toString(),
              width: size,
            },
          ]}
          urlEndpoint={process.env.REACT_APP_IMAGEKIT_URL_ENDPOINT}
          width={size}
        />
        &nbsp;+&nbsp;
        {images[1] === "item" ? (
          t("recommendations.item")
        ) : (
          <IKImage
            alt={t(`recommendations.${images[1]}`)}
            className={size === "16" ? "h-4" : "h-6"}
            height={size}
            lqip={{ active: true }}
            path={`${images[1]}.png`}
            transformation={[
              {
                dpr: window.devicePixelRatio.toString(),
                width: size,
              },
            ]}
            urlEndpoint={process.env.REACT_APP_IMAGEKIT_URL_ENDPOINT}
            width={size}
          />
        )}
      </span>
    );
  } else {
    return data.label;
  }
};

const Option = (props: OptionProps<{ label: string; value: Filter }>) => {
  delete props.innerProps.onMouseMove;
  delete props.innerProps.onMouseOver;
  return <components.Option {...props}>{props.children}</components.Option>;
};

const Search = () => {
  const { t } = useTranslation();
  const [currentTheme, setCurrentTheme] = useState<Theme>();
  const [placeholder, setPlaceholder] = useState<string>(t("placeholder"));
  const dispatch = useAppDispatch();
  const filters = useAppSelector((state) => state.filters);
  const mql = window.matchMedia("(prefers-color-scheme: dark)");

  useEffect(() => {
    setCurrentTheme(() => {
      if (
        localStorage.getItem("theme") === Theme.Dark ||
        ((!localStorage.getItem("theme") ||
          localStorage.getItem("theme") === Theme.Auto) &&
          mql.matches)
      ) {
        return Theme.Dark;
      } else {
        return Theme.Light;
      }
    });

    mql.onchange = () => {
      setCurrentTheme(localStorage.getItem("theme") as Theme);
    };
  }, [mql]);

  const formatFilter = (filter: Filter) => {
    if (filter.kind === Kind.Emojis && !filter.filePath) {
      filter = {
        ...filter,
        filePath: emojis?.find((emoji) => emoji._id === filter.value)?.filePath,
      };
    }

    return {
      label:
        filter.kind === Kind.Levels
          ? `${t("level")} ${filter.value}`
          : t(`${filter.kind}.${filter.value}`),
      value: filter,
    };
  };

  const formatOptionLabel = (data: { label: string; value: Filter }) => {
    return (
      <div className="flex items-center gap-1">
        {data.value.kind === Kind.Emojis ? (
          <IKImage
            alt={data.label}
            height="20"
            lqip={{ active: true }}
            path={data.value.filePath!}
            transformation={[
              {
                di:
                  currentTheme === Theme.Dark ? "dark-error.png" : "error.png",
                dpr: window.devicePixelRatio.toString(),
                width: "20",
                height: "20",
                cropMode: "pad_resize",
              },
            ]}
            urlEndpoint={process.env.REACT_APP_IMAGEKIT_URL_ENDPOINT}
            width="20"
          />
        ) : (
          <FontAwesomeIcon
            className="h-5 w-5 text-purple-500 dark:text-purple-400"
            icon={filterIcon(data.value)}
          />
        )}

        <span className="text-slate-900 dark:text-slate-100">
          {data.value.kind === Kind.Recommendations
            ? recommendationLabel(data)
            : data.label}
        </span>
        {filterImage(data.value)}
      </div>
    );
  };

  const filterImage = (filter: Filter) => {
    if (filter.kind === Kind.Powers) {
      return (
        <IKImage
          alt={t(`${filter.kind}.${filter.value}`)}
          height="24"
          lqip={{ active: true }}
          path={`/powers/${filter.value}.png`}
          transformation={[
            {
              di: currentTheme === Theme.Dark ? "dark-error.png" : "error.png",
              dpr: window.devicePixelRatio.toString(),
              width: "24",
            },
          ]}
          urlEndpoint={process.env.REACT_APP_IMAGEKIT_URL_ENDPOINT}
          width="24"
        />
      );
    }
  };

  const fetcher = (url: string) => axios.get(url).then((res) => res.data);

  // Blitz Modifiers
  const {
    data: blitzModifiers,
  }: { data: Array<Document>; mutate: KeyedMutator<any> } = useSWR(
    `${process.env.REACT_APP_API_ENDPOINT}/blitz-modifiers`,
    fetcher,
  );

  // Boxes
  const { data: boxes }: { data: Array<Document>; mutate: KeyedMutator<any> } =
    useSWR(`${process.env.REACT_APP_API_ENDPOINT}/boxes`, fetcher);

  // Emojis
  const { data: emojis }: { data: Array<Emoji>; mutate: KeyedMutator<any> } =
    useSWR(`${process.env.REACT_APP_API_ENDPOINT}/emojis/list`, fetcher);

  // Groups
  const { data: groups }: { data: Array<Document>; mutate: KeyedMutator<any> } =
    useSWR(`${process.env.REACT_APP_API_ENDPOINT}/groups`, fetcher);

  // Levels
  const levels = Array.from(Array(6).keys()).map((value) => ({
    kind: Kind.Levels,
    value: value.toString(),
  }));

  // Other
  const { data: other }: { data: Array<Document>; mutate: KeyedMutator<any> } =
    useSWR(`${process.env.REACT_APP_API_ENDPOINT}/other`, fetcher);

  // Powers
  const { data: powers }: { data: Array<Document>; mutate: KeyedMutator<any> } =
    useSWR(`${process.env.REACT_APP_API_ENDPOINT}/powers`, fetcher);

  // Recommendations
  const {
    data: recommendations,
  }: { data: Array<Document>; mutate: KeyedMutator<any> } = useSWR(
    `${process.env.REACT_APP_API_ENDPOINT}/recommendations`,
    fetcher,
  );

  // Tags
  const { data: tags }: { data: Array<Document>; mutate: KeyedMutator<any> } =
    useSWR(`${process.env.REACT_APP_API_ENDPOINT}/tags`, fetcher);

  // Worlds
  const { data: worlds }: { data: Array<Document>; mutate: KeyedMutator<any> } =
    useSWR(`${process.env.REACT_APP_API_ENDPOINT}/worlds`, fetcher);

  const { isAuthenticated } = useKindeAuth();
  const groupedOptions: Array<{
    label: string;
    options: Filter[];
  }> = [
    {
      label: "tags.title",
      options: tags?.map((tag) => ({ kind: Kind.Tags, value: tag._id })),
    },
    {
      label: "worlds.title",
      options: worlds?.map((world) => ({
        kind: Kind.Worlds,
        value: world._id,
      })),
    },
    {
      label: "powers.title",
      options: powers?.map((power) => ({
        kind: Kind.Powers,
        value: power._id,
      })),
    },
    {
      label: "blitzModifiers.title",
      options: blitzModifiers?.map((blitzModifier) => ({
        kind: Kind.BlitzModifiers,
        value: blitzModifier._id,
      })),
    },
    {
      label: "recommendations.title",
      options: recommendations?.map((recommendation) => ({
        kind: Kind.Recommendations,
        value: recommendation._id,
      })),
    },
    {
      label: "groups.title",
      options: groups?.map((group) => ({
        kind: Kind.Groups,
        value: group._id,
      })),
    },
    {
      label: "boxes.title",
      options: boxes?.map((box) => ({ kind: Kind.Boxes, value: box._id })),
    },
    {
      label: "other.title",
      options: other?.map((other) => ({ kind: Kind.Other, value: other._id })),
    },
    {
      label: "emojis.title",
      options: emojis?.map((emoji) => ({
        filePath: emoji.filePath,
        kind: Kind.Emojis,
        value: emoji._id,
      })),
    },
  ];

  if (isAuthenticated) {
    groupedOptions.splice(
      groupedOptions.findIndex(
        (groupedOption) => groupedOption.label === "other",
      ),
      0,
      {
        label: "levels",
        options: levels,
      },
    );
  }

  return (
    <>
      <label className="sr-only" htmlFor="filter">
        Filter
      </label>
      <Select
        blurInputOnSelect={false}
        components={{
          ClearIndicator,
          DropdownIndicator,
          Menu,
          MenuList: WindowedMenuList,
          MultiValueLabel,
          MultiValueRemove,
          Option,
        }}
        formatOptionLabel={(data) => formatOptionLabel(data)}
        inputId="filter"
        isMulti
        onBlur={() => setPlaceholder(t("placeholder"))}
        onChange={(
          event: MultiValue<{
            label: string;
            value: Filter;
          }>,
        ) => {
          dispatch(loadingChanged(true));
          dispatch(filtersChanged(event));
        }}
        onFocus={() => {
          setPlaceholder(`${t("typeToFilter")}…`);
        }}
        options={groupedOptions?.map((group) => ({
          label: t(group.label),
          options: group.options
            ?.filter(
              (option) =>
                !filters?.some(
                  (filter) =>
                    filter.kind === option.kind &&
                    filter.value === option.value,
                ),
            )
            ?.sort((a, b) =>
              a.kind !== Kind.Boxes && a.kind !== Kind.Levels
                ? t(`${a.kind}.${a.value}`).localeCompare(
                    t(`${b.kind}.${b.value}`),
                  )
                : 0,
            )
            ?.map((filter) => formatFilter(filter)),
        }))}
        placeholder={placeholder}
        styles={styles}
        value={filters.map((filter) => formatFilter(filter))}
      ></Select>
    </>
  );
};

export default memo(Search);
